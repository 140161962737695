import React from 'react'
import { EloIconProps } from '../types'

export const EloGooglePayIcon: React.FC<EloIconProps> = ({ width = 31, height = 12 }) => (
  <svg width={width} height={height} viewBox='0 0 31 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_767_1954)'>
      <path
        d='M14.2749 5.83604V9.37405H13.1521V0.637207H16.1287C16.8831 0.637207 17.5264 0.888669 18.0527 1.39159C18.5907 1.89452 18.8597 2.50855 18.8597 3.2337C18.8597 3.97639 18.5907 4.59042 18.0527 5.0875C17.5322 5.58458 16.8889 5.83019 16.1287 5.83019H14.2749V5.83604ZM14.2749 1.71323V4.76001H16.1521C16.5965 4.76001 16.9708 4.60797 17.2632 4.30972C17.5615 4.01148 17.7135 3.6489 17.7135 3.23955C17.7135 2.83604 17.5615 2.47931 17.2632 2.18107C16.9708 1.87113 16.6024 1.71908 16.1521 1.71908H14.2749V1.71323Z'
        fill='#3C4043'
      />
      <path
        d='M21.7953 3.19873C22.6257 3.19873 23.2807 3.42095 23.7602 3.8654C24.2398 4.30984 24.4795 4.91803 24.4795 5.68996V9.37417H23.4094V8.54376H23.3626C22.9006 9.22797 22.2807 9.56715 21.5088 9.56715C20.848 9.56715 20.2982 9.37417 19.8538 8.98236C19.4094 8.59054 19.1871 8.10516 19.1871 7.52037C19.1871 6.90049 19.4211 6.40926 19.8889 6.04668C20.3567 5.67826 20.9825 5.49698 21.7602 5.49698C22.4269 5.49698 22.9766 5.61978 23.4035 5.8654V5.60809C23.4035 5.21627 23.2515 4.88879 22.9415 4.61394C22.6316 4.33908 22.269 4.20458 21.8538 4.20458C21.2281 4.20458 20.731 4.46774 20.3684 4.9999L19.3801 4.38002C19.924 3.59054 20.731 3.19873 21.7953 3.19873ZM20.345 7.53791C20.345 7.83031 20.4678 8.07592 20.7193 8.26891C20.9649 8.46189 21.2573 8.5613 21.5906 8.5613C22.0643 8.5613 22.4854 8.38587 22.8538 8.03499C23.2222 7.68411 23.4094 7.27475 23.4094 6.80107C23.0585 6.52622 22.5731 6.38586 21.9474 6.38586C21.4912 6.38586 21.1111 6.49698 20.807 6.71335C20.4971 6.94142 20.345 7.21627 20.345 7.53791Z'
        fill='#3C4043'
      />
      <path
        d='M30.5849 3.3916L26.8422 11.9998H25.6843L27.0761 8.98809L24.6083 3.3916H25.8305L27.6083 7.684H27.6317L29.3627 3.3916H30.5849Z'
        fill='#3C4043'
      />
      <path
        d='M9.80879 5.12304C9.80879 4.75696 9.77604 4.40667 9.71522 4.06982H5.00879V5.99965L7.71932 6.00023C7.60937 6.64234 7.25557 7.18971 6.71347 7.55462V8.80667H8.32692C9.26902 7.93474 9.80879 6.64585 9.80879 5.12304Z'
        fill='#4285F4'
      />
      <path
        d='M6.714 7.55452C6.26488 7.85744 5.68652 8.03463 5.00991 8.03463C3.70289 8.03463 2.59412 7.15393 2.19704 5.9668H0.532715V7.25802C1.35728 8.89428 3.05201 10.0171 5.00991 10.0171C6.36312 10.0171 7.49997 9.57206 8.32745 8.80598L6.714 7.55452Z'
        fill='#34A853'
      />
      <path
        d='M2.04035 5.00898C2.04035 4.67565 2.09591 4.35343 2.19708 4.05051V2.75928H0.532749C0.191813 3.43589 0 4.19963 0 5.00898C0 5.81834 0.192398 6.58208 0.532749 7.25869L2.19708 5.96746C2.09591 5.66454 2.04035 5.34232 2.04035 5.00898Z'
        fill='#FABB05'
      />
      <path
        d='M5.00991 1.98246C5.7485 1.98246 6.40991 2.23684 6.93213 2.73392L8.36195 1.30526C7.49353 0.496491 6.36137 0 5.00991 0C3.0526 0 1.35728 1.12281 0.532715 2.75906L2.19704 4.05029C2.59412 2.86316 3.70289 1.98246 5.00991 1.98246Z'
        fill='#E94235'
      />
    </g>
    <defs>
      <clipPath id='clip0_767_1954'>
        <rect width='30.6667' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
