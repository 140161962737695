import React, { Fragment } from 'react'
import classNames from 'classnames'

import PoweredByElopage from 'ui/icons/PoweredByElopage'
import {
  EloMastercardSecureCodeIcon,
  EloSecurePurchaseIcon,
  EloSSLIcon,
  EloVerifiedByVisaIcon,
} from '@elo-ui/components/icons/payment-methods'

import { ELOPAGE_BADGE_KEY } from 'constants/options.constants'

interface Props {
  isEmbeded: string
  isFree: boolean
  sellerUsername: string
  sellerAffiliateLandingToken: string
  isAppActive: (key: string) => boolean
  themePrefs: {
    noFollow?: boolean
  }
  ppTemplate?: {
    sealCustomizationEnabled?: boolean
    sealUploadEnabled?: boolean
    trustSealsSettings?: {
      visa: boolean
      elopage: boolean
      mastercard: boolean
      moneyBack: boolean
      monochromaticColorEnabled: boolean
      quality: boolean
      sslSecure: boolean
    }
    templatesTrustSeals?: {
      id: number
      selected: boolean
      trustSeal: Record<string, any>
    }[]
  }
}

export const PurchaseSecure: React.FC<Props> = React.memo((props) => {
  const { isEmbeded, isFree, sellerUsername, sellerAffiliateLandingToken, isAppActive, themePrefs, ppTemplate } = props
  const { sealCustomizationEnabled, sealUploadEnabled, trustSealsSettings, templatesTrustSeals } = ppTemplate || {}

  const privacyLink = `/s/${sellerUsername}/document/privacy`
  const imprintLink = `/s/${sellerUsername}/document/imprint`
  const termsLink = `/s/${sellerUsername}/document/terms_of_business`

  const showPoweredBy = !isAppActive(ELOPAGE_BADGE_KEY)
  const links = (
    <Fragment>
      <a className='secure-info__link' href={imprintLink} rel={themePrefs?.noFollow ? 'nofollow' : ''} target='_blank'>
        {I18n.t('react.shop.footer.imprint')}
      </a>

      <a className='secure-info__link' href={termsLink} rel={themePrefs?.noFollow ? 'nofollow' : ''} target='_blank'>
        {I18n.t('react.shop.common.terms')}
      </a>

      <a className='secure-info__link' href={privacyLink} rel={themePrefs?.noFollow ? 'nofollow' : ''} target='_blank'>
        {I18n.t('react.shop.common.privacy')}
      </a>

      {sellerAffiliateLandingToken && (
        <a
          className='secure-info__link'
          href={`/s/${sellerUsername}/publisher-landing/${sellerAffiliateLandingToken}`}
          target='_blank'
        >
          {I18n.t('react.shop.common.affiliate')}
        </a>
      )}
    </Fragment>
  )
  const defaultTrustSeals = {
    sslSecure: <EloSSLIcon width={90} height={32} />,
    visa: <EloVerifiedByVisaIcon width={72} height={32} />,
    mastercard: <EloMastercardSecureCodeIcon width={125} height={32} />,
    elopage: <EloSecurePurchaseIcon width={110} height={32} />,
  }

  return (
    <div className='secure-info'>
      <div className='icons'>
        {isEmbeded && (
          <Fragment>
            {showPoweredBy && (
              <div className='icon'>
                <PoweredByElopage />
              </div>
            )}
            {isFree && <div>{links}</div>}
          </Fragment>
        )}
        {!isFree && (
          <Fragment>
            {sealCustomizationEnabled ? (
              <>
                {Object.keys(defaultTrustSeals).map((seal, index) => {
                  if (!trustSealsSettings[seal]) {
                    return
                  }

                  const iconClasses = classNames('icon', {
                    'icon-grayscale': trustSealsSettings.monochromaticColorEnabled,
                  })

                  return (
                    <div key={index} className={iconClasses}>
                      {defaultTrustSeals[seal]}
                    </div>
                  )
                })}
              </>
            ) : (
              <>
                {Object.values(defaultTrustSeals).map((seal, index) => (
                  <div key={index} className='icon'>
                    {seal}
                  </div>
                ))}
              </>
            )}
            {sealUploadEnabled && templatesTrustSeals[templatesTrustSeals.length - 1]?.trustSeal?.file?.custom && (
              <div>
                <img
                  className='trust-seal-custom-image'
                  src={templatesTrustSeals[templatesTrustSeals.length - 1].trustSeal.file.custom}
                  alt='trust_seal'
                />
              </div>
            )}
          </Fragment>
        )}
      </div>
      {isEmbeded && !isFree && <div className='secure-info__footer'>{links}</div>}
    </div>
  )
})
