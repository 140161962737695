import React from 'react'
import { EloIconProps } from '../types'

export const EloPrzelewy24Icon: React.FC<EloIconProps> = ({ width = 36, height = 12 }) => (
  <svg width={width} height={height} viewBox='0 0 36 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_767_1965)'>
      <path
        d='M7.4488 6.91706L10.3904 6.91113L10.2836 7.58129L7.82243 9.92386H9.88626L9.76765 10.6415H6.7312L6.85574 9.90014L9.21018 7.64059H7.33019L7.4488 6.91706Z'
        fill='#D13239'
      />
      <path
        d='M4.24036 5.77267C4.17512 5.70744 4.08616 5.65406 3.98534 5.61255C3.87859 5.57103 3.75998 5.54138 3.64137 5.51766C3.49311 5.49394 3.35077 5.48208 3.24995 5.47615C3.15506 5.47021 3.09576 5.47021 3.09576 5.47021H1.80882H0.854002L0 10.6417H0.800627L1.11495 8.7261L2.66876 8.73203C2.66876 8.73203 3.27367 8.75575 3.70068 8.53039C4.12768 8.30503 4.24629 7.78907 4.24629 7.78907C4.24629 7.78907 4.29373 7.59929 4.33525 7.36207C4.37676 7.10705 4.4242 6.79866 4.442 6.63261C4.45386 6.56144 4.45979 6.514 4.45979 6.514C4.45979 6.514 4.47165 6.46062 4.47165 6.37759C4.47165 6.26491 4.45386 6.09292 4.3649 5.92687C4.32339 5.87349 4.2878 5.82012 4.24036 5.77267ZM3.62358 6.68598C3.62358 6.69784 3.55241 7.11298 3.45752 7.60522C3.42194 7.795 3.2796 7.89582 3.11355 7.9314C2.84074 7.99071 2.54421 7.97885 2.54421 7.97885L1.24542 7.97291L1.53009 6.25305L2.70434 6.25898C2.70434 6.25898 2.78144 6.25898 2.89412 6.25898C3.01866 6.25898 3.18472 6.26491 3.32705 6.2827C3.44566 6.30049 3.54055 6.32422 3.5702 6.3598C3.61172 6.40724 3.62358 6.47248 3.62951 6.53179C3.63544 6.60888 3.62358 6.68005 3.62358 6.68598Z'
        fill='#D13239'
      />
      <path d='M14.3698 5.47021H15.1408L14.2809 10.6417H13.5099L14.3698 5.47021Z' fill='#D13239' />
      <path
        d='M18.4796 6.91706L19.2209 6.91113L19.4819 9.53838L20.6146 6.91113H21.5279L21.7948 9.55024L22.9275 6.91113H23.6985L22.0676 10.6415H21.1602L20.8993 8.03201L19.7488 10.6415H18.8592L18.4796 6.91706Z'
        fill='#D13239'
      />
      <path
        d='M12.9524 6.9468C12.7389 6.87563 12.3652 6.86377 12.045 6.86377C11.7366 6.8697 11.6002 6.88156 11.4816 6.90528C11.4816 6.90528 10.936 6.98238 10.6276 7.36787C10.3192 7.75336 10.2243 8.58957 10.2243 8.58957C10.2243 8.58957 10.0405 9.51473 10.0938 9.82312C10.1472 10.1315 10.2421 10.4162 10.592 10.5526C10.9419 10.689 11.2384 10.6831 11.2384 10.6831C11.2384 10.6831 11.8611 10.7305 12.3297 10.6238C12.7982 10.517 13.0473 10.1849 13.0473 10.1849C13.0473 10.1849 13.1599 10.0426 13.237 9.87057C13.3141 9.69858 13.3378 9.5859 13.3438 9.56811L13.3912 9.36647H12.5906C12.5906 9.36647 12.5491 9.89429 12.1162 9.94174C11.6832 9.98918 11.4579 9.97139 11.3689 9.96546C11.2859 9.95953 10.8233 9.98325 10.8589 9.59776C10.8589 9.59183 10.8589 9.5859 10.8589 9.57404C10.8767 9.13518 10.93 9.0225 10.93 9.0225L13.4387 9.01657L13.5454 8.39386C13.6818 7.68812 13.5929 7.15437 12.9524 6.9468ZM12.7804 8.3049H11.0605L11.1257 8.03209C11.1257 8.03209 11.1851 7.81859 11.3037 7.72963C11.4223 7.64067 11.5765 7.62288 11.7188 7.61102C11.8611 7.59916 12.2407 7.56951 12.555 7.63474C12.6558 7.65254 12.7567 7.71184 12.7804 7.8008C12.8456 7.99651 12.7804 8.3049 12.7804 8.3049Z'
        fill='#D13239'
      />
      <path
        d='M10.8805 9.58203C10.8805 9.5887 10.8805 9.59536 10.8805 9.60203C10.8739 9.63536 10.8805 9.58203 10.8805 9.58203Z'
        fill='#D13239'
      />
      <path
        d='M17.679 6.9468C17.4655 6.87563 17.0919 6.86377 16.7717 6.86377C16.4633 6.8697 16.3269 6.88156 16.2083 6.90528C16.2083 6.90528 15.6627 6.98238 15.3543 7.36787C15.0459 7.75336 14.951 8.58957 14.951 8.58957C14.951 8.58957 14.7671 9.51473 14.8205 9.82312C14.8739 10.1315 14.9688 10.4162 15.3187 10.5526C15.6686 10.689 15.9651 10.6831 15.9651 10.6831C15.9651 10.6831 16.5878 10.7305 17.0563 10.6238C17.5249 10.5111 17.7739 10.1849 17.7739 10.1849C17.7739 10.1849 17.8866 10.0426 17.9637 9.87057C18.0408 9.69858 18.0645 9.5859 18.0705 9.56811L18.1179 9.36647H17.3173C17.3173 9.36647 17.2758 9.89429 16.8428 9.94174C16.4099 9.98918 16.1845 9.97139 16.0956 9.97139C16.0126 9.96546 15.55 9.98918 15.5856 9.60369C15.5856 9.59776 15.5856 9.59183 15.5856 9.57997C15.6033 9.14111 15.6567 9.02843 15.6567 9.02843L18.1654 9.0225L18.2721 8.39979C18.4085 7.69405 18.3255 7.15437 17.679 6.9468ZM17.5071 8.3049H15.7872L15.8524 8.03209C15.8524 8.03209 15.9117 7.81859 16.0303 7.72963C16.149 7.64067 16.3032 7.62288 16.4455 7.61102C16.5878 7.59916 16.9674 7.56951 17.2817 7.63474C17.3825 7.65254 17.4833 7.71184 17.5071 7.8008C17.5723 7.99651 17.5071 8.3049 17.5071 8.3049Z'
        fill='#D13239'
      />
      <path
        d='M24.5823 6.91713L25.0745 9.6452L26.4682 6.91713L27.251 6.92306L25.2406 10.8194C25.2406 10.8194 24.8788 11.5252 24.6534 11.7031C24.4281 11.881 24.2917 11.964 24.1078 11.9818C23.924 11.9996 23.8469 12.0115 23.6749 11.9818L23.4851 11.9463L23.6037 11.2524C23.6037 11.2524 23.918 11.3117 24.1019 11.2346C24.2857 11.1634 24.434 10.8432 24.434 10.8432L24.5289 10.683L23.7994 6.90527L24.5823 6.91713Z'
        fill='#D13239'
      />
      <path
        d='M27.5416 7.2847H28.3481L28.3956 6.97038C28.3956 6.97038 28.4845 6.40104 28.6743 6.29429C28.7336 6.25871 28.8344 6.22906 28.953 6.20534C29.1665 6.16975 29.4334 6.16382 29.6528 6.16975C29.9909 6.18161 30.1154 6.18754 30.4594 6.22313C30.8034 6.26464 30.7144 6.59082 30.7144 6.59082L30.6492 7.08306C30.6492 7.08306 30.6195 7.30249 30.5424 7.43889C30.4713 7.56344 30.2815 7.64646 30.1688 7.68205C29.9019 7.77101 28.9886 8.0023 28.9886 8.0023L28.271 8.20987C28.271 8.20987 27.8322 8.34034 27.5831 8.60722C27.334 8.88002 27.2391 9.18841 27.2035 9.35447C27.1679 9.51459 26.9781 10.6355 26.9781 10.6355H30.8508L30.9813 9.8645L27.9211 9.87043L27.9745 9.55611C27.9745 9.55611 28.0101 9.22993 28.1405 9.12318C28.1821 9.08759 28.1998 9.04608 28.4489 8.95712C28.5972 8.90375 29.0954 8.77327 29.0954 8.77327L30.2518 8.45895C30.2518 8.45895 30.8805 8.29883 31.1295 7.94892C31.3786 7.60495 31.4735 6.94666 31.4735 6.94666C31.4735 6.94666 31.5388 6.30616 31.4913 6.10452C31.4379 5.90288 31.2482 5.66565 31.0228 5.5589C30.7915 5.45808 30.5543 5.39285 29.8545 5.40471C29.1606 5.41657 28.8107 5.44622 28.4608 5.5767C28.105 5.70717 27.9033 5.94439 27.7728 6.2765C27.6246 6.60268 27.5416 7.2847 27.5416 7.2847Z'
        fill='#B3B2B1'
      />
      <path
        d='M34.8303 8.72603L35.3759 5.47607H34.4152L31.4202 8.69044L31.2838 9.50293H33.8992L33.7094 10.6475H34.516L34.7058 9.50293H35.4471L35.5775 8.72603H34.8303ZM34.0356 8.72603H32.3454L34.4033 6.53172L34.0356 8.72603Z'
        fill='#B3B2B1'
      />
      <path
        d='M5.93652 5.89714H8.40364C8.40364 5.89714 8.95518 5.45235 9.3466 5.16175C9.73802 4.87708 10.4556 4.42043 10.4556 4.42043L9.06193 3.76807C9.06193 3.76807 7.88175 4.49753 7.38358 4.8415C6.89728 5.16175 5.93652 5.89714 5.93652 5.89714Z'
        fill='#B3B2B1'
      />
      <path
        d='M11.2325 3.96389L10.0879 3.19292C10.0879 3.19292 11.1198 2.60579 12.5016 2.05425C13.8775 1.50864 14.6129 1.27734 14.6129 1.27734L14.8442 2.36857C14.8442 2.36857 13.5217 2.81336 12.7626 3.1692C11.9797 3.50724 11.2325 3.96389 11.2325 3.96389Z'
        fill='#B3B2B1'
      />
      <path
        d='M15.6922 2.13102L15.4906 1.01607C15.4906 1.01607 16.9021 0.642445 18.1949 0.399291C19.4878 0.156138 21.2017 0.043457 21.2017 0.043457L20.6324 1.76925C20.6324 1.76925 19.126 1.56168 17.7086 1.75739C16.6115 1.88786 15.6922 2.13102 15.6922 2.13102Z'
        fill='#B3B2B1'
      />
      <path
        d='M21.5339 1.91776L22.4888 0.00218671C22.4888 0.00218671 24.5763 -0.0393273 26.3792 0.23941C28.1821 0.518146 29.8308 0.945147 29.7952 0.962939L25.2228 3.35296C25.2228 3.35296 24.1552 2.67687 22.8268 2.26173C22.0795 2.0423 21.5339 1.91776 21.5339 1.91776Z'
        fill='#B3B2B1'
      />
      <path
        d='M26.0471 3.87505L27.0494 4.64009H35.3047C35.3047 4.64009 35.287 4.37321 35.0675 3.99366C34.9311 3.75644 34.682 3.50735 34.4211 3.24641C34.3262 3.15152 33.9466 2.85499 33.662 2.67114C32.9325 2.19669 32.5233 2.01285 31.7701 1.6748L26.0471 3.87505Z'
        fill='#B3B2B1'
      />
      <path
        d='M6.47023 6.91113C6.16184 6.91113 5.86531 7.03568 5.61623 7.17208L5.65774 6.91113H4.83932L4.18103 10.6237H5.00538L5.36714 8.56576C5.44424 8.15062 5.75263 7.63466 6.36348 7.63466H6.79048L6.92095 6.91113H6.47023Z'
        fill='#D13239'
      />
    </g>
    <defs>
      <clipPath id='clip0_767_1965'>
        <rect width='36' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
