import React from 'react'
import { EloIconProps } from '../types'

export const EloPaypalIcon: React.FC<EloIconProps> = ({ width = 44, height = 12 }) => (
  <svg width={width} height={height} viewBox='0 0 44 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_767_1958)'>
      <path
        d='M5.56864 3.2934C5.39001 4.55354 4.46936 4.55354 3.57619 4.55354H3.06777L3.42504 2.20509C3.45252 2.06189 3.56245 1.96165 3.69986 1.96165H3.93346C4.53806 1.96165 5.11519 1.96165 5.40375 2.31965C5.58238 2.53445 5.63735 2.84948 5.56864 3.2934ZM5.18389 0.0141602H1.83108C1.59748 0.0141602 1.40511 0.185998 1.36388 0.429435L0.00351925 9.39364C-0.0239629 9.56547 0.113448 9.72299 0.27834 9.72299H1.88604C2.11964 9.72299 2.31201 9.55115 2.35324 9.30772L2.72425 6.88767C2.76547 6.64423 2.95784 6.47239 3.1777 6.47239H4.23576C6.44807 6.47239 7.72599 5.35545 8.05577 3.1502C8.20692 2.19077 8.05577 1.43182 7.6298 0.887669C7.14886 0.329196 6.29692 0.0141602 5.18389 0.0141602Z'
        fill='#253B80'
      />
      <path
        d='M12.9751 6.50138C12.8239 7.4608 12.0957 8.1052 11.1613 8.1052C10.6941 8.1052 10.3231 7.94768 10.0757 7.64696C9.84214 7.36057 9.74595 6.93097 9.82839 6.47274C9.97955 5.52763 10.7078 4.8546 11.6285 4.8546C12.0819 4.8546 12.4529 5.01212 12.7003 5.31283C12.9476 5.62787 13.0438 6.04314 12.9751 6.50138ZM15.2149 3.25078H13.6072C13.4698 3.25078 13.3461 3.35102 13.3324 3.49422L13.2637 3.96677L13.1537 3.79493C12.8102 3.2651 12.027 3.09326 11.2575 3.09326C9.48487 3.09326 7.95961 4.4966 7.67105 6.45842C7.5199 7.43216 7.73976 8.37727 8.27566 9.03598C8.77033 9.63741 9.47113 9.88085 10.2956 9.88085C11.7247 9.88085 12.5216 8.92142 12.5216 8.92142L12.4529 9.37966C12.4255 9.5515 12.5629 9.72333 12.7278 9.72333H14.1706C14.4042 9.72333 14.5965 9.5515 14.6378 9.30806L15.5034 3.58014C15.5172 3.4083 15.3798 3.25078 15.2149 3.25078Z'
        fill='#253B80'
      />
      <path
        d='M23.7618 3.25049H22.1541C22.003 3.25049 21.8518 3.33641 21.7694 3.46529L19.5433 6.8734L18.5952 3.59416C18.5402 3.39369 18.3478 3.25049 18.1417 3.25049H16.5615C16.3691 3.25049 16.2317 3.45097 16.3004 3.63712L18.073 9.07865L16.3966 11.5417C16.2592 11.7421 16.3966 11.9999 16.6302 11.9999H18.2379C18.3891 11.9999 18.5402 11.9283 18.6227 11.7851L23.9954 3.70872C24.1191 3.50824 23.9954 3.25049 23.7618 3.25049Z'
        fill='#253B80'
      />
      <path
        d='M29.4918 3.2934C29.3132 4.55354 28.3925 4.55354 27.4993 4.55354H26.9909L27.3482 2.20509C27.3757 2.06189 27.4856 1.96165 27.623 1.96165H27.8566C28.4612 1.96165 29.0383 1.96165 29.3269 2.31965C29.5055 2.53445 29.5605 2.84948 29.4918 3.2934ZM29.107 0.0141602H25.7542C25.5206 0.0141602 25.3283 0.185998 25.3008 0.429435L23.9404 9.39364C23.9129 9.56547 24.0503 9.72299 24.2152 9.72299H25.9329C26.0978 9.72299 26.2352 9.60843 26.2489 9.43659L26.6337 6.90199C26.6749 6.65855 26.8673 6.48671 27.0871 6.48671H28.1452C30.3575 6.48671 31.6354 5.36977 31.9652 3.16452C32.1163 2.20509 31.9652 1.44614 31.5392 0.901988C31.0858 0.329196 30.2338 0.0141602 29.107 0.0141602Z'
        fill='#179BD7'
      />
      <path
        d='M36.8982 6.50138C36.747 7.4608 36.0188 8.1052 35.0844 8.1052C34.6172 8.1052 34.2462 7.94768 33.9988 7.64696C33.7652 7.36057 33.669 6.93097 33.7515 6.47274C33.9026 5.52763 34.6447 4.86892 35.5516 4.86892C36.005 4.86892 36.376 5.02644 36.6234 5.32715C36.8707 5.62787 36.9669 6.04314 36.8982 6.50138ZM39.138 3.25078H37.5303C37.3929 3.25078 37.2692 3.35102 37.2555 3.49422L37.1868 3.96677L37.0768 3.79493C36.7333 3.2651 35.9501 3.09326 35.1806 3.09326C33.408 3.09326 31.8827 4.4966 31.5941 6.45842C31.443 7.43216 31.6629 8.37727 32.1988 9.03598C32.6934 9.63741 33.3942 9.88085 34.2187 9.88085C35.6478 9.88085 36.4447 8.92142 36.4447 8.92142L36.376 9.37966C36.3485 9.5515 36.486 9.72333 36.6509 9.72333H38.0937C38.3273 9.72333 38.5196 9.5515 38.5471 9.30806L39.4128 3.58014C39.4403 3.4083 39.3166 3.25078 39.138 3.25078Z'
        fill='#179BD7'
      />
      <path
        d='M41.0342 0.257757L39.6601 9.3938C39.6327 9.56563 39.7701 9.72315 39.935 9.72315H41.3228C41.5564 9.72315 41.7488 9.55131 41.79 9.30788L43.1504 0.343675C43.1778 0.171838 43.0404 0 42.8755 0H41.3228C41.1717 0.0143198 41.048 0.114558 41.0342 0.257757Z'
        fill='#179BD7'
      />
    </g>
    <defs>
      <clipPath id='clip0_767_1958'>
        <rect width='43.3333' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
