import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  viewbox: PropTypes.string,
  width: PropTypes.number,
}

const defaultProps = {
  className: 'menu__icon',
  // viewbox: '0 0 140 45',
  // width: 140,
  // height: 45
  viewbox: '0 0 78 25',
  width: 78,
  height: 25,
}

/**
 * SVG Templates Icon
 */
const PoweredByElopage = (props) => {
  const { height, viewBox, width } = props
  return (
    <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.647 13.9214C10.2657 14.3028 9.73856 14.5388 9.15641 14.5384C8.81064 14.5384 8.48472 14.4552 8.19662 14.3076C7.80255 14.1057 7.48035 13.7835 7.27847 13.3894C7.1316 13.1014 7.04843 12.7758 7.04843 12.4304V12.3754C7.05332 12.1768 7.08589 11.985 7.14208 11.8044C7.14208 11.8037 7.14247 11.803 7.14208 11.8026C7.24324 11.4748 7.29755 11.1264 7.29794 10.7653C7.29794 10.3045 7.20914 9.86428 7.04804 9.46048C6.86939 9.01167 6.60076 8.60857 6.26511 8.27257C5.92946 7.93653 5.52601 7.66794 5.07719 7.48959C4.67335 7.32853 4.23319 7.24012 3.77241 7.23973C3.41128 7.23973 3.06289 7.29444 2.73509 7.39559H2.73322C2.55266 7.45178 2.36123 7.48435 2.1623 7.48924H2.10724C1.76221 7.48924 1.4363 7.40642 1.14822 7.2592C0.754117 7.05728 0.431949 6.73512 0.23003 6.34101C0.0831802 6.05409 1.52588e-05 5.72817 1.52588e-05 5.382C1.52588e-05 4.79985 0.235649 4.27276 0.617011 3.89141C0.998371 3.51005 1.52583 3.27441 2.10761 3.27441H11.1891C11.2303 3.27441 11.264 3.30813 11.264 3.34934V12.4308C11.264 13.013 11.0284 13.5401 10.6466 13.9218L10.647 13.9214Z'
        fill='#2BFF99'
      />
      <path
        d='M3.84735 12.7616C5.00472 12.7616 5.94299 11.8234 5.94299 10.666C5.94299 9.5086 5.00472 8.57037 3.84735 8.57037C2.68998 8.57037 1.75172 9.50865 1.75172 10.666C1.75172 11.8234 2.68997 12.7616 3.84735 12.7616Z'
        fill='#2BFF99'
      />
      <g clip-path='url(#clip0_745_4158)'>
        <path
          d='M25.7715 8.18407C25.7715 6.35961 24.4161 4.98691 22.609 4.98691C20.7846 4.98691 19.4292 6.35961 19.4292 8.18407C19.4292 10.0085 20.7846 11.3813 22.609 11.3813C24.4161 11.3813 25.7715 10.0086 25.7715 8.18407ZM17.9001 8.18407C17.9001 5.54292 19.7941 3.54468 22.3311 3.54468C23.808 3.54468 25.0243 4.23972 25.7715 5.35179L26.0334 3.75321H27.2658V12.615H26.0334L25.7715 11.0164C25.0243 12.1284 23.808 12.8235 22.3311 12.8235C19.7941 12.8235 17.9001 10.8252 17.9001 8.18407Z'
          fill='#000004'
        />
        <path
          d='M37.045 8.18408C37.045 6.35962 35.6897 4.98692 33.8652 4.98692C32.0581 4.98692 30.7028 6.35962 30.7028 8.18408C30.7028 10.0085 32.0581 11.3813 33.8652 11.3813C35.6897 11.3813 37.045 10.0086 37.045 8.18408ZM29.2085 0.104248H30.7028V5.3518C31.45 4.23973 32.6663 3.54469 34.1433 3.54469C36.6802 3.54469 38.5915 5.54293 38.5915 8.18408C38.5915 10.8252 36.6802 12.8235 34.1433 12.8235C32.6663 12.8235 31.45 12.1284 30.7028 11.0164L30.4409 12.615H29.2085V0.104248Z'
          fill='#000004'
        />
        <path d='M41.5228 0.104248H40.0285V12.615H41.5228V0.104248Z' fill='#000004' />
        <path
          d='M52.0562 10.2446C51.2958 11.7971 49.6498 12.8235 47.6772 12.8235C44.9492 12.8235 42.9162 10.8252 42.9162 8.18407C42.9162 5.54292 44.9492 3.54468 47.6251 3.54468C50.3183 3.54468 52.3339 5.54292 52.3339 8.18407C52.3339 8.3926 52.3166 8.61849 52.2992 8.80962H44.5147C44.7754 10.3735 46.0265 11.4508 47.6946 11.4508C48.8888 11.4508 49.9365 10.8972 50.4594 10.0579L52.0562 10.2446ZM44.5321 7.47167H50.7528C50.4921 5.99469 49.241 4.91737 47.6251 4.91737C46.0438 4.91737 44.8275 5.95994 44.5321 7.47167Z'
          fill='#000004'
        />
        <path
          d='M54.2175 5.10856H52.6015V3.75321H54.2175V2.74539C54.2175 1.00778 55.2774 0 57.1714 0H58.2313V1.3727H57.3104C56.32 1.3727 55.7118 1.9635 55.7118 2.91918V3.75321H58.0923V5.10856H55.7118V12.615H54.2175V5.10856Z'
          fill='#000004'
        />
        <path
          d='M62.5092 12.5803L58.6517 3.75323H60.3546L63.3433 10.8252L66.3319 3.75323H68L62.6656 15.9164H61.0496L62.5092 12.5803Z'
          fill='#000004'
        />
      </g>
      <defs>
        <clipPath id='clip0_745_4158'>
          <rect width='50.0999' height='15.9164' fill='white' transform='translate(17.9001)' />
        </clipPath>
      </defs>
    </svg>
  )
}

PoweredByElopage.displayName = 'PoweredByElopage'
PoweredByElopage.propTypes = propTypes
PoweredByElopage.defaultProps = defaultProps

export default PoweredByElopage
