import React from 'react'
import { EloIconProps } from '../types'

export const EloAmExIcon: React.FC<EloIconProps> = ({ width = 40, height = 40 }) => (
  <svg width={width} height={height} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M0 0H40V40H0V0Z' fill='#016FD0' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.4911 33.6711V19.3378L40 19.36V23.32L37.0511 26.4711L40 29.6511V33.6933H35.2933L32.7911 30.9333L30.3066 33.7044L14.4911 33.6711Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.1934 32.1V20.9155H25.6778V23.4911H19.2645V25.24H25.5245V27.7733H19.2645V29.4911H25.6778V32.1H16.1934Z'
      fill='#016FD0'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25.6289 32.1L30.8778 26.5L25.6289 20.9155H29.6911L32.8978 24.46L36.1133 20.9155H40V21.0044L34.8645 26.5L40 31.94V32.1H36.0734L32.8111 28.52L29.58 32.1H25.6289Z'
      fill='#016FD0'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.68 6.61554H21.8289L23.9889 11.52V6.61554H31.58L32.8889 10.2911L34.2022 6.61554H40V20.9489H9.36444L15.68 6.61554Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.8444 8.17334L11.8822 19.3489H15.2866L16.2222 17.1111H21.2955L22.2311 19.3489H25.72L20.7778 8.17334H16.8466H16.8444ZM17.2711 14.6022L18.76 11.0445L20.2466 14.6022H17.2711Z'
      fill='#016FD0'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25.6711 19.3467V8.17114L30.4556 8.18892L32.9178 15.0578L35.3956 8.17114H40V19.3467L37.0378 19.3734V11.6956L34.24 19.3467H31.5356L28.68 11.6711V19.3467H25.6711Z'
      fill='#016FD0'
    />
  </svg>
)
