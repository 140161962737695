import React from 'react'
import { EloIconProps } from '../types'

export const EloSecurePurchaseIcon: React.FC<EloIconProps> = ({ width = 158, height = 40 }) => (
  <svg width={width} height={height} viewBox='0 0 145 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20.207 40C31.367 40 40.414 31.0456 40.414 20C40.414 8.95433 31.367 0 20.207 0C9.047 0 0 8.95429 0 20C0 31.0457 9.04698 40 20.207 40Z'
      fill='#2BFF99'
    />
    <path
      d='M27.5403 29.1096C26.8917 29.7516 25.9952 30.1488 25.005 30.1483C24.417 30.1483 23.8626 30.0083 23.3726 29.7598C22.7023 29.4198 22.1543 28.8776 21.8108 28.2141C21.5611 27.7293 21.4196 27.1813 21.4196 26.5998V26.5071C21.428 26.173 21.4833 25.85 21.579 25.5461C21.579 25.5448 21.5796 25.5435 21.579 25.543C21.751 24.9911 21.8433 24.4046 21.844 23.7968C21.844 23.0211 21.693 22.2801 21.419 21.6003C21.1151 20.845 20.6581 20.1663 20.0873 19.6006C19.5165 19.0351 18.8302 18.583 18.0668 18.2828C17.38 18.0116 16.6313 17.8628 15.8476 17.8621C15.2333 17.8621 14.6408 17.9541 14.0832 18.1245H14.0801C13.7729 18.2191 13.4473 18.274 13.109 18.2821H13.0153C12.4285 18.2821 11.8742 18.1428 11.3842 17.895C10.7139 17.555 10.1659 17.0126 9.82245 16.3493C9.57141 15.8644 9.42995 15.3164 9.42995 14.7337C9.42995 13.7538 9.83073 12.8665 10.4794 12.2245C11.128 11.5826 12.0252 11.1853 13.0153 11.1853H28.4617C28.5318 11.1853 28.5892 11.242 28.5892 11.3114V26.5987C28.5892 27.5785 28.1895 28.4671 27.5403 29.1096Z'
      fill='#000004'
    />
    <path
      d='M16.003 27.213C17.987 27.213 19.5953 25.6212 19.5953 23.6575C19.5953 21.6939 17.987 20.1021 16.003 20.1021C14.0191 20.1021 12.4107 21.6939 12.4107 23.6575C12.4107 25.6212 14.019 27.213 16.003 27.213Z'
      fill='#000004'
    />
    <path
      d='M51.5872 21.9883C49.784 21.9883 48.2172 22.9976 48.2172 24.6468C48.2172 28.1914 53.7882 26.3329 53.7882 28.7821C53.7882 29.7053 52.9052 30.2838 51.6742 30.2838C50.1447 30.2838 49.2742 29.3606 49.2742 27.9699L48.0058 28.5729C48.2297 30.3576 49.6845 31.4283 51.6742 31.4283C53.6637 31.4283 55.156 30.4314 55.156 28.7083C55.156 25.0899 49.5727 26.8499 49.5727 24.5606C49.5727 23.6621 50.4182 23.133 51.5872 23.133C52.9673 23.133 53.8005 23.9453 53.8005 25.0776L55.0317 24.5606C54.8948 23.1576 53.6015 21.9883 51.5872 21.9883Z'
      fill='#485056'
    />
    <path
      d='M59.2084 24.6348C57.3432 24.6348 55.9878 26.0871 55.9878 28.0563C55.9878 30.0379 57.2811 31.4286 59.2954 31.4286C60.4767 31.4286 61.6084 30.9733 62.2178 29.9641L61.2603 29.1394C60.8996 29.9518 60.2779 30.3333 59.3204 30.3333C58.2883 30.3333 57.4799 29.7303 57.3432 28.4503H62.2799C62.3047 28.2533 62.3047 28.0933 62.3047 27.9333C62.3047 25.9763 61.1234 24.6348 59.2084 24.6348ZM57.3556 27.4903C57.5297 26.3086 58.2757 25.7303 59.1836 25.7303C60.2529 25.7303 60.9121 26.4194 60.9121 27.4903H57.3556Z'
      fill='#485056'
    />
    <path
      d='M66.567 24.6348C64.5774 24.6348 63.1225 26.0379 63.1225 28.0318C63.1225 30.0624 64.5277 31.4286 66.567 31.4286C67.9225 31.4286 69.0665 30.6901 69.5017 29.6809L68.3825 29.0163C68.1835 29.7179 67.4375 30.2471 66.567 30.2471C65.2737 30.2471 64.453 29.398 64.453 28.0318C64.453 26.6656 65.311 25.8041 66.567 25.8041C67.4002 25.8041 68.1587 26.2595 68.37 27.0595L69.5017 26.3825C69.0913 25.3733 67.9348 24.6348 66.567 24.6348Z'
      fill='#485056'
    />
    <path
      d='M76.103 24.8438H74.8098V28.3391C74.8098 29.5699 74.188 30.3206 73.0938 30.3206C72.1612 30.3206 71.6887 29.8037 71.6887 28.7576V24.8438H70.3953V28.9299C70.3953 30.4929 71.3032 31.4282 72.8078 31.4282C73.6658 31.4282 74.3622 31.1329 74.8098 30.4067V31.2437H76.103L76.103 24.8438Z'
      fill='#485056'
    />
    <path
      d='M77.5921 24.8425V31.2425H78.8853V28.1408C78.8853 26.6147 79.6314 25.9625 80.6884 25.9625C80.9248 25.9625 81.1236 25.9994 81.3474 26.0609V24.7687C81.1609 24.7194 81.0366 24.707 80.8376 24.707C80.2035 24.707 79.3703 24.9532 78.8853 25.9009V24.8425L77.5921 24.8425Z'
      fill='#485056'
    />
    <path
      d='M85.0783 24.6348C83.2131 24.6348 81.8576 26.0871 81.8576 28.0563C81.8576 30.0379 83.1509 31.4286 85.1653 31.4286C86.3466 31.4286 87.4783 30.9733 88.0876 29.9641L87.1301 29.1394C86.7694 29.9518 86.1478 30.3333 85.1903 30.3333C84.1581 30.3333 83.3498 29.7303 83.2131 28.4503H88.1497C88.1746 28.2533 88.1746 28.0933 88.1746 27.9333C88.1746 25.9763 86.9933 24.6348 85.0783 24.6348ZM83.2254 27.4903C83.3996 26.3086 84.1456 25.7303 85.0534 25.7303C86.1227 25.7303 86.7819 26.4194 86.7819 27.4903H83.2254Z'
      fill='#485056'
    />
    <path
      d='M92.6797 31.2434H94.0228V27.6249H96.5097C98.1387 27.6249 99.4817 26.6649 99.4817 24.9173C99.4817 23.2679 98.2879 22.1973 96.4476 22.1973H92.6797V31.2434ZM94.0228 26.4311V23.3911H96.4724C97.5294 23.3911 98.1388 24.0311 98.1388 24.9173C98.1388 25.8279 97.4796 26.4311 96.4476 26.4311H94.0228Z'
      fill='#485056'
    />
    <path
      d='M106.059 24.8438H104.766V28.3391C104.766 29.5699 104.144 30.3206 103.05 30.3206C102.117 30.3206 101.644 29.8037 101.644 28.7576V24.8438H100.351V28.9299C100.351 30.4929 101.259 31.4282 102.764 31.4282C103.622 31.4282 104.318 31.1329 104.766 30.4067V31.2437H106.059L106.059 24.8438Z'
      fill='#485056'
    />
    <path
      d='M107.299 24.8425V31.2425H108.593V28.1408C108.593 26.6147 109.339 25.9625 110.396 25.9625C110.632 25.9625 110.831 25.9994 111.055 26.0609V24.7687C110.868 24.7194 110.744 24.707 110.545 24.707C109.911 24.707 109.078 24.9532 108.593 25.9009V24.8425L107.299 24.8425Z'
      fill='#485056'
    />
    <path
      d='M114.761 24.6348C112.771 24.6348 111.316 26.0379 111.316 28.0318C111.316 30.0624 112.721 31.4286 114.761 31.4286C116.116 31.4286 117.26 30.6901 117.695 29.6809L116.576 29.0163C116.377 29.7179 115.631 30.2471 114.761 30.2471C113.467 30.2471 112.647 29.398 112.647 28.0318C112.647 26.6656 113.505 25.8041 114.761 25.8041C115.594 25.8041 116.352 26.2595 116.564 27.0595L117.695 26.3825C117.285 25.3733 116.129 24.6348 114.761 24.6348Z'
      fill='#485056'
    />
    <path
      d='M122.22 24.6341C121.188 24.6341 120.529 25.0649 120.131 25.7173V22.1973H118.838V31.2434H120.131V27.8096C120.131 26.5296 120.802 25.8034 121.884 25.8034C122.892 25.8034 123.377 26.3696 123.377 27.5388V31.2434H124.67V27.3541C124.67 25.6311 123.799 24.6341 122.22 24.6341Z'
      fill='#485056'
    />
    <path
      d='M125.694 29.5824C125.694 30.7024 126.589 31.4286 127.883 31.4286C128.778 31.4286 129.624 31.1086 130.109 30.4318L130.395 31.2441H131.526L131.315 30.0133V26.8133C131.315 25.3979 130.283 24.6348 128.616 24.6348C127.137 24.6348 125.993 25.4718 125.818 26.6903L126.987 27.1333C126.987 26.1733 127.684 25.6563 128.616 25.6563C129.412 25.6563 130.046 25.9394 130.046 26.6163C130.046 27.2318 129.773 27.3056 127.82 27.6379C126.54 27.8718 125.694 28.4871 125.694 29.5824ZM127.062 29.5209C127.062 28.9056 127.534 28.6594 128.181 28.5241C129.462 28.2903 129.847 28.2286 130.046 28.0563V28.6718C130.046 29.6686 129.387 30.4318 128.169 30.4318C127.46 30.4318 127.062 30.0379 127.062 29.5209Z'
      fill='#485056'
    />
    <path
      d='M135.087 24.6348C133.657 24.6348 132.451 25.4718 132.451 26.6286C132.451 29.2009 136.554 28.1056 136.554 29.5333C136.554 30.1241 135.97 30.3948 135.211 30.3948C134.241 30.3948 133.557 29.9271 133.508 28.9548L132.252 29.4841C132.463 30.6533 133.557 31.4286 135.211 31.4286C136.815 31.4286 137.947 30.7394 137.947 29.4471C137.947 26.9241 133.781 27.9579 133.781 26.5548C133.781 26.0379 134.266 25.6563 135.087 25.6563C136.019 25.6563 136.579 26.1363 136.641 26.9486L137.86 26.4933C137.673 25.3733 136.616 24.6348 135.087 24.6348Z'
      fill='#485056'
    />
    <path
      d='M141.904 24.6348C140.038 24.6348 138.683 26.0871 138.683 28.0563C138.683 30.0379 139.976 31.4286 141.991 31.4286C143.172 31.4286 144.304 30.9733 144.913 29.9641L143.956 29.1394C143.595 29.9518 142.973 30.3333 142.016 30.3333C140.984 30.3333 140.175 29.7303 140.038 28.4503H144.975C145 28.2533 145 28.0933 145 27.9333C145 25.9763 143.819 24.6348 141.904 24.6348ZM140.051 27.4903C140.225 26.3086 140.971 25.7303 141.879 25.7303C142.948 25.7303 143.607 26.4194 143.607 27.4903H140.051Z'
      fill='#485056'
    />
    <path
      d='M50.1738 14.0049C52.1093 13.6719 52.3808 13.5977 52.3808 12.9815C52.3808 12.3037 51.7519 12.0195 50.9629 12.0195C50.0381 12.0195 49.3476 12.5381 49.3476 13.499L48.1894 13.0557C48.3613 11.835 49.4961 10.9961 50.9629 10.9961C52.6152 10.9961 53.6387 11.7607 53.6387 13.1787V16.3838L53.8476 17.6172H52.7265L52.4424 16.8037C51.9619 17.4814 51.123 17.8017 50.2353 17.8017C48.9531 17.8017 48.0654 17.0752 48.0654 15.9531C48.0654 14.8555 48.9043 14.2393 50.1738 14.0049ZM50.5195 16.8037C51.7275 16.8037 52.3808 16.0391 52.3808 15.04V14.4238C52.1836 14.5967 51.8017 14.6582 50.5312 14.8926C49.8906 15.0283 49.4219 15.2744 49.4219 15.8916C49.4219 16.4092 49.8164 16.8037 50.5195 16.8037Z'
      fill='#000004'
    />
    <path
      d='M56.4443 8.55566V12.0693C56.9619 11.416 57.7383 10.9961 58.7744 10.9961C60.7099 10.9961 62.0166 12.4883 62.0166 14.4111C62.0166 16.3223 60.7099 17.8018 58.7617 17.8018C57.7265 17.8018 56.9619 17.3828 56.4443 16.7422V17.6172H55.1621V8.55566H56.4443ZM58.5527 16.6309C59.8594 16.6309 60.6855 15.6816 60.6855 14.4111C60.6855 13.1299 59.8594 12.1553 58.5527 12.1553C57.2822 12.1553 56.4072 13.1172 56.4072 14.4111C56.4072 15.6943 57.2822 16.6309 58.5527 16.6309Z'
      fill='#000004'
    />
    <path d='M64.4648 8.55566V17.6172H63.1826V8.55566H64.4648Z' fill='#000004' />
    <path
      d='M71.8798 14.3008C71.8798 14.4609 71.8798 14.6211 71.8554 14.8183H66.9609C67.0956 16.1006 67.8974 16.7051 68.9208 16.7051C69.8701 16.7051 70.4863 16.3222 70.8437 15.5088L71.7929 16.335C71.1894 17.3457 70.0673 17.8017 68.8964 17.8017C66.8984 17.8017 65.6162 16.4092 65.6162 14.4238C65.6162 12.4512 66.9609 10.9961 68.8095 10.9961C70.7089 10.9961 71.8798 12.3408 71.8798 14.3008ZM70.499 13.8564C70.499 12.7842 69.8457 12.0937 68.7851 12.0937C67.8847 12.0937 67.1455 12.6728 66.9726 13.8564H70.499Z'
      fill='#000004'
    />
    <path
      d='M76.5468 8.45704V9.55372C76.3242 9.5049 76.0527 9.4795 75.8066 9.4795C75.1777 9.4795 74.7949 9.7881 74.7949 10.5156V11.2061H76.6816V12.3154H74.7949V17.6172H73.5136V12.3154H72.3672V11.2061H73.5136V10.4785C73.5136 9.2334 74.3144 8.3457 75.5605 8.3457C75.8808 8.3457 76.25 8.37014 76.5468 8.45704Z'
      fill='#000004'
    />
    <path
      d='M78.6289 11.2061L80.6015 16.002L82.4882 11.2061H83.9062L80.1582 20.2676H78.7773L79.8984 17.5186L77.1875 11.2061H78.6289Z'
      fill='#000004'
    />
  </svg>
)
