import React from 'react'

export const EloStripeIcon = ({ width = 31, height = 12 }) => (
  <svg width={width} height={height} viewBox='0 0 31 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_767_1968)'>
      <path
        d='M30.04 6.20014C30.04 4.14941 29.0053 2.53125 27.0277 2.53125C25.0417 2.53125 23.8401 4.14941 23.8401 6.18412C23.8401 8.59534 25.2586 9.81296 27.2947 9.81296C28.2877 9.81296 29.0387 9.59667 29.6061 9.29226V7.69013C29.0387 7.96249 28.3878 8.13072 27.5617 8.13072C26.7523 8.13072 26.0347 7.85835 25.9429 6.91309H30.0233C30.0233 6.80895 30.04 6.3924 30.04 6.20014ZM25.9179 5.43913C25.9179 4.53392 26.4936 4.15742 27.0193 4.15742C27.5283 4.15742 28.0707 4.53392 28.0707 5.43913H25.9179Z'
        fill='#6772E5'
      />
      <path
        d='M20.6192 2.53125C19.8014 2.53125 19.2757 2.89974 18.9837 3.15608L18.8752 2.65942H17.0394V11.9999L19.1255 11.5753L19.1339 9.30829C19.4343 9.51656 19.8765 9.81296 20.6109 9.81296C22.1045 9.81296 23.4647 8.65942 23.4647 6.12004C23.4563 3.79694 22.0795 2.53125 20.6192 2.53125ZM20.1185 8.05061C19.6262 8.05061 19.3342 7.88239 19.1339 7.67411L19.1255 4.70214C19.3425 4.46983 19.6429 4.30962 20.1185 4.30962C20.8779 4.30962 21.4036 5.12671 21.4036 6.17611C21.4036 7.24954 20.8862 8.05061 20.1185 8.05061Z'
        fill='#6772E5'
      />
      <path d='M14.1689 2.05874L16.2634 1.62617V0L14.1689 0.424566V2.05874Z' fill='#6772E5' />
      <path d='M14.1689 2.66748H16.2634V9.67683H14.1689V2.66748Z' fill='#6772E5' />
      <path
        d='M11.9242 3.26055L11.7907 2.66776H9.98828V9.67711H12.0744V4.92678C12.5667 4.30995 13.4012 4.4221 13.6598 4.51022V2.66776C13.3928 2.57164 12.4165 2.3954 11.9242 3.26055Z'
        fill='#6772E5'
      />
      <path
        d='M7.75203 0.929199L5.71599 1.34575L5.70764 7.76231C5.70764 8.94789 6.63388 9.82106 7.86886 9.82106C8.5531 9.82106 9.05377 9.7009 9.32914 9.5567V7.93054C9.06211 8.03467 7.74369 8.40317 7.74369 7.21758V4.37379H9.32914V2.66752H7.74369L7.75203 0.929199Z'
        fill='#6772E5'
      />
      <path
        d='M2.11115 4.70214C2.11115 4.38973 2.37817 4.26957 2.82043 4.26957C3.45461 4.26957 4.25567 4.45381 4.88985 4.78225V2.89974C4.19726 2.63539 3.51302 2.53125 2.82043 2.53125C1.1265 2.53125 0 3.38038 0 4.79827C0 7.00922 3.17089 6.65675 3.17089 7.61002C3.17089 7.97851 2.83712 8.09867 2.36983 8.09867C1.67724 8.09867 0.792724 7.82631 0.091789 7.45782V9.36436C0.867824 9.68479 1.6522 9.82097 2.36983 9.82097C4.10547 9.82097 5.29873 8.99587 5.29873 7.56196C5.29039 5.17477 2.11115 5.59934 2.11115 4.70214Z'
        fill='#6772E5'
      />
    </g>
    <defs>
      <clipPath id='clip0_767_1968'>
        <rect width='30.6667' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
