import React from 'react'
import { EloIconProps } from '../types'

export const EloBankWireDeIcon: React.FC<EloIconProps> = ({ width = 37, height = 12 }) => (
  <svg width={width} height={height} viewBox='0 0 37 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_767_1964)'>
      <path
        d='M11.0036 11.9998H0.427598C0.254992 11.9998 0.11377 11.8586 0.11377 11.686V11.1996C0.11377 11.027 0.254992 10.8857 0.427598 10.8857H11.0193C11.1919 10.8857 11.3331 11.027 11.3331 11.1996V11.686C11.3174 11.8586 11.1762 11.9998 11.0036 11.9998Z'
        fill='#1A79D1'
      />
      <path
        d='M3.48737 10.3365H1.82408C1.65148 10.3365 1.51025 10.1953 1.51025 10.0227V4.97008C1.51025 4.79747 1.65148 4.65625 1.82408 4.65625H3.50306C3.67567 4.65625 3.81689 4.79747 3.81689 4.97008V10.0227C3.8012 10.1953 3.67567 10.3365 3.48737 10.3365Z'
        fill='#1A79D1'
      />
      <path
        d='M6.54719 10.3365H4.8839C4.71129 10.3365 4.57007 10.1953 4.57007 10.0227V4.97008C4.57007 4.79747 4.71129 4.65625 4.8839 4.65625H6.56288C6.73548 4.65625 6.8767 4.79747 6.8767 4.97008V10.0227C6.86101 10.1953 6.71979 10.3365 6.54719 10.3365Z'
        fill='#1A79D1'
      />
      <path
        d='M9.59137 10.3365H7.92809C7.75548 10.3365 7.61426 10.1953 7.61426 10.0227V4.97008C7.61426 4.79747 7.75548 4.65625 7.92809 4.65625H9.60707C9.77967 4.65625 9.92089 4.79747 9.92089 4.97008V10.0227C9.9052 10.1953 9.76398 10.3365 9.59137 10.3365Z'
        fill='#1A79D1'
      />
      <path
        d='M0.160821 3.44819L5.51159 0.0588428C5.62143 -0.0196143 5.77834 -0.0196143 5.90387 0.0588428L11.2546 3.44819C11.5528 3.63648 11.4272 4.10722 11.0663 4.10722H0.380501C0.0039072 4.10722 -0.137315 3.63648 0.160821 3.44819Z'
        fill='#1A79D1'
      />
      <path
        d='M13.9379 6.22563L12.6355 2.17725H13.6397L14.1262 3.88761C14.2674 4.37404 14.3929 4.82909 14.4871 5.33122H14.5028C14.6126 4.84478 14.7381 4.35835 14.8637 3.9033L15.3815 2.17725H16.3544L15.0049 6.22563H13.9379Z'
        fill='#5E6266'
      />
      <path
        d='M19.4141 4.719C19.4141 5.78601 18.6453 6.28814 17.8607 6.28814C16.9977 6.28814 16.3386 5.72325 16.3386 4.78176C16.3386 3.82459 16.9663 3.22832 17.9078 3.22832C18.8179 3.21262 19.4141 3.84028 19.4141 4.719ZM17.2958 4.75038C17.2958 5.25251 17.4998 5.6291 17.8921 5.6291C18.253 5.6291 18.4727 5.2682 18.4727 4.73469C18.4727 4.29533 18.3 3.85597 17.8921 3.85597C17.4684 3.87166 17.2958 4.32671 17.2958 4.75038Z'
        fill='#5E6266'
      />
      <path
        d='M19.9791 4.26421C19.9791 3.82486 19.9791 3.54241 19.9478 3.29135H20.7323L20.7637 3.82486H20.7951C20.952 3.3855 21.2972 3.21289 21.5797 3.21289C21.6581 3.21289 21.7052 3.21289 21.768 3.22858V4.09161C21.6895 4.07592 21.6267 4.06023 21.5169 4.06023C21.1874 4.06023 20.952 4.23283 20.8892 4.51528C20.8735 4.57804 20.8735 4.64081 20.8735 4.71927V6.22564H19.9634V4.26421H19.9791Z'
        fill='#5E6266'
      />
      <path
        d='M23.1644 4.53084C23.2428 4.40531 23.3213 4.27978 23.3841 4.16994L23.9803 3.29122H25.0787L24.0274 4.48377L25.22 6.22551H24.0902L23.3684 5.01728L23.133 5.29972V6.22551H22.2229V1.97314H23.133V4.53084H23.1644Z'
        fill='#5E6266'
      />
      <path
        d='M27.966 5.51946C27.966 5.80191 27.9817 6.06866 28.0131 6.22557H27.1972L27.1501 5.92744H27.1344C26.9461 6.16281 26.648 6.28834 26.2871 6.28834C25.6908 6.28834 25.3456 5.84898 25.3456 5.39393C25.3456 4.62505 26.036 4.26415 27.0716 4.26415V4.23277C27.0716 4.07585 26.9932 3.85617 26.5381 3.85617C26.24 3.85617 25.9262 3.96601 25.7222 4.07585L25.5496 3.49527C25.7536 3.38543 26.1615 3.22852 26.695 3.22852C27.6679 3.22852 27.9817 3.8091 27.9817 4.49952V5.51946H27.966ZM27.0873 4.84473C26.6009 4.84473 26.24 4.95457 26.24 5.29978C26.24 5.53515 26.3969 5.64499 26.6009 5.64499C26.8206 5.64499 27.0089 5.48808 27.0716 5.31547C27.0873 5.2684 27.0873 5.22132 27.0873 5.15856V4.84473Z'
        fill='#5E6266'
      />
      <path
        d='M28.6878 5.441C28.8604 5.55084 29.2056 5.66068 29.4724 5.66068C29.7548 5.66068 29.8647 5.56653 29.8647 5.42531C29.8647 5.2684 29.7705 5.18994 29.441 5.0801C28.829 4.87611 28.5937 4.54659 28.5937 4.20138C28.5937 3.65218 29.0644 3.22852 29.7862 3.22852C30.1314 3.22852 30.4296 3.30697 30.6022 3.40112L30.4452 4.02878C30.3197 3.95032 30.053 3.85617 29.8019 3.85617C29.5822 3.85617 29.4567 3.95032 29.4567 4.09154C29.4567 4.23277 29.5665 4.31122 29.9274 4.43675C30.4923 4.62505 30.712 4.92319 30.712 5.34685C30.712 5.89605 30.2883 6.30403 29.441 6.30403C29.0644 6.30403 28.7192 6.20988 28.4995 6.10004L28.6878 5.441Z'
        fill='#5E6266'
      />
      <path
        d='M31.2925 5.441C31.4652 5.55084 31.8104 5.66068 32.0771 5.66068C32.3596 5.66068 32.4694 5.56653 32.4694 5.42531C32.4694 5.2684 32.3753 5.18994 32.0457 5.0801C31.4338 4.87611 31.1984 4.54659 31.1984 4.20138C31.1984 3.65218 31.6691 3.22852 32.3909 3.22852C32.7362 3.22852 33.0343 3.30697 33.2069 3.40112L33.05 4.02878C32.9245 3.95032 32.6577 3.85617 32.4066 3.85617C32.187 3.85617 32.0614 3.95032 32.0614 4.09154C32.0614 4.23277 32.1713 4.31122 32.5322 4.43675C33.0971 4.62505 33.3167 4.92319 33.3167 5.34685C33.3167 5.89605 32.8931 6.30403 32.0457 6.30403C31.6691 6.30403 31.3239 6.20988 31.1042 6.10004L31.2925 5.441Z'
        fill='#5E6266'
      />
      <path
        d='M34.6035 5.04872C34.6349 5.42531 35.0115 5.61361 35.4352 5.61361C35.749 5.61361 36 5.56653 36.2511 5.48808L36.3766 6.10004C36.0785 6.22557 35.7176 6.28834 35.3096 6.28834C34.3211 6.28834 33.7405 5.70776 33.7405 4.79766C33.7405 4.06016 34.1955 3.22852 35.2155 3.22852C36.157 3.22852 36.5179 3.96601 36.5179 4.68782C36.5179 4.84473 36.5022 4.98595 36.4865 5.04872H34.6035ZM35.6862 4.42106C35.6862 4.20138 35.5921 3.82479 35.1684 3.82479C34.7918 3.82479 34.6349 4.18569 34.6035 4.42106H35.6862Z'
        fill='#5E6266'
      />
      <path
        d='M13.4044 7.70068V9.34827C13.4044 9.89747 13.6398 10.1642 13.985 10.1642C14.3616 10.1642 14.5813 9.91316 14.5813 9.34827V7.70068H15.0991V9.31689C15.0991 10.1956 14.644 10.5722 13.9693 10.5722C13.3103 10.5722 12.8866 10.2113 12.8866 9.31689V7.70068H13.4044ZM13.3416 7.26132C13.3416 7.12009 13.4515 7.01025 13.5927 7.01025C13.7339 7.01025 13.8438 7.12009 13.8438 7.26132C13.8438 7.40254 13.7496 7.51238 13.5927 7.51238C13.4515 7.51238 13.3416 7.40254 13.3416 7.26132ZM14.1733 7.26132C14.1733 7.12009 14.2831 7.01025 14.4244 7.01025C14.5656 7.01025 14.6754 7.12009 14.6754 7.26132C14.6754 7.40254 14.5813 7.51238 14.4244 7.51238C14.2831 7.51238 14.1733 7.40254 14.1733 7.26132Z'
        fill='#5E6266'
      />
      <path
        d='M15.664 7.54395H16.1818V8.76787H16.1975C16.323 8.56389 16.5427 8.43835 16.8566 8.43835C17.3587 8.43835 17.7196 8.84633 17.7039 9.47399C17.7039 10.2115 17.2331 10.5724 16.7781 10.5724C16.5113 10.5724 16.276 10.4782 16.1347 10.2272H16.1191L16.0877 10.541H15.6483C15.664 10.3998 15.664 10.1801 15.664 9.96042V7.54395ZM16.1818 9.67798C16.1818 9.72505 16.1818 9.75643 16.1975 9.80351C16.2446 10.0075 16.4329 10.1644 16.6526 10.1644C16.9821 10.1644 17.1704 9.91335 17.1704 9.48968C17.1704 9.12878 16.9978 8.83064 16.6526 8.83064C16.4486 8.83064 16.2446 8.98755 16.1975 9.20723C16.1818 9.23862 16.1818 9.28569 16.1818 9.34846V9.67798Z'
        fill='#5E6266'
      />
      <path
        d='M18.4884 9.64629C18.5041 10.0229 18.7865 10.1798 19.116 10.1798C19.3514 10.1798 19.524 10.1484 19.6809 10.0857L19.7594 10.4309C19.5868 10.5093 19.3357 10.5564 19.0533 10.5564C18.3942 10.5564 18.002 10.1484 18.002 9.52076C18.002 8.95587 18.3472 8.42236 19.0062 8.42236C19.6652 8.42236 19.8849 8.97156 19.8849 9.41092C19.8849 9.50507 19.8692 9.58353 19.8692 9.6306H18.4884V9.64629ZM19.3828 9.28539C19.3828 9.09709 19.3043 8.78327 18.9591 8.78327C18.6453 8.78327 18.5041 9.06571 18.4884 9.28539H19.3828Z'
        fill='#5E6266'
      />
      <path
        d='M20.2773 9.1441C20.2773 8.86166 20.2773 8.67336 20.2616 8.48506H20.701L20.7166 8.87735H20.7323C20.8265 8.5949 21.0775 8.43799 21.2972 8.43799C21.3443 8.43799 21.3757 8.43799 21.4228 8.45368V8.94011C21.3757 8.92442 21.3286 8.92442 21.2658 8.92442C21.0148 8.92442 20.8579 9.08134 20.8108 9.31671C20.7951 9.36378 20.7951 9.41086 20.7951 9.47362V10.5406H20.2773V9.1441Z'
        fill='#5E6266'
      />
      <path
        d='M22.1759 8.48535L22.3798 9.41114C22.4269 9.63082 22.474 9.8505 22.5211 10.0702H22.5368C22.5838 9.8505 22.6466 9.61513 22.6937 9.41114L22.9604 8.48535H23.3841L23.6352 9.39545C23.6979 9.63082 23.745 9.8505 23.7921 10.0859H23.8078C23.8391 9.86619 23.8862 9.64652 23.949 9.39545L24.1687 8.48535H24.6708L24.0274 10.5252H23.5567L23.3056 9.69359C23.2429 9.47391 23.1958 9.28561 23.1487 9.03455H23.133C23.086 9.28561 23.0389 9.4896 22.9761 9.69359L22.7094 10.5252H22.2229L21.611 8.48535H22.1759Z'
        fill='#5E6266'
      />
      <path
        d='M25.3298 9.64629C25.3455 10.0229 25.628 10.1798 25.9575 10.1798C26.1928 10.1798 26.3654 10.1484 26.5224 10.0857L26.6008 10.4309C26.4282 10.5093 26.1772 10.5564 25.8947 10.5564C25.2357 10.5564 24.8434 10.1484 24.8434 9.52076C24.8434 8.95587 25.1886 8.42236 25.8476 8.42236C26.5067 8.42236 26.7264 8.97156 26.7264 9.41092C26.7264 9.50507 26.7107 9.58353 26.7107 9.6306H25.3298V9.64629ZM26.2242 9.28539C26.2242 9.09709 26.1458 8.78327 25.8006 8.78327C25.4867 8.78327 25.3455 9.06571 25.3298 9.28539H26.2242Z'
        fill='#5E6266'
      />
      <path
        d='M27.728 7.90452C27.728 8.06143 27.6068 8.18696 27.399 8.18696C27.2085 8.18696 27.0873 8.06143 27.0873 7.90452C27.0873 7.7476 27.2085 7.62207 27.399 7.62207C27.6068 7.62207 27.728 7.7476 27.728 7.90452ZM27.1219 10.525V8.4851H27.6933V10.525H27.1219Z'
        fill='#5E6266'
      />
      <path
        d='M28.17 10.0386C28.2799 10.117 28.4995 10.1955 28.6878 10.1955C28.9075 10.1955 29.0174 10.1013 29.0174 9.96012C29.0174 9.8189 28.9389 9.75613 28.6878 9.66198C28.2956 9.52076 28.1229 9.31677 28.1229 9.06571C28.1229 8.70481 28.4211 8.42236 28.8918 8.42236C29.1115 8.42236 29.3155 8.46944 29.441 8.54789L29.3312 8.9088C29.2527 8.86172 29.0801 8.79896 28.8918 8.79896C28.7035 8.79896 28.6094 8.89311 28.6094 9.01864C28.6094 9.14417 28.7035 9.20693 28.9703 9.30108C29.3469 9.42661 29.5195 9.61491 29.5195 9.92874C29.5195 10.3053 29.237 10.5721 28.6878 10.5721C28.4368 10.5721 28.2171 10.5093 28.0602 10.4309L28.17 10.0386Z'
        fill='#5E6266'
      />
      <path
        d='M31.7791 9.91334C31.7791 10.1644 31.7948 10.3527 31.7948 10.5253H31.3397L31.3083 10.2115H31.2926C31.1985 10.3527 31.0102 10.5567 30.6336 10.5567C30.257 10.5567 29.9275 10.337 29.9275 9.66227V8.46973H30.4453V9.58382C30.4453 9.92903 30.5551 10.1487 30.8219 10.1487C31.0259 10.1487 31.1671 10.0075 31.2142 9.86626C31.2299 9.81919 31.2456 9.77211 31.2456 9.70935V8.46973H31.7634V9.91334H31.7791Z'
        fill='#5E6266'
      />
      <path
        d='M32.3282 9.09745C32.3282 8.86208 32.3125 8.65809 32.3125 8.48548H32.7676L32.7989 8.79931H32.8146C32.9088 8.6424 33.1285 8.4541 33.4737 8.4541C33.8346 8.4541 34.1955 8.68947 34.1955 9.33282V10.5411H33.6777V9.3642C33.6777 9.06607 33.5678 8.84639 33.2854 8.84639C33.0814 8.84639 32.9402 8.98761 32.8774 9.14452C32.8617 9.1916 32.846 9.25436 32.846 9.30144V10.5097H32.3282V9.09745Z'
        fill='#5E6266'
      />
      <path
        d='M36.5806 10.2268C36.5806 10.6505 36.4865 10.9643 36.2825 11.1526C36.0785 11.3409 35.7804 11.4037 35.4979 11.4037C35.2312 11.4037 34.9644 11.3409 34.7918 11.2467L34.9017 10.8545C35.0272 10.9329 35.2469 11.0114 35.4979 11.0114C35.8274 11.0114 36.0785 10.8388 36.0785 10.3994V10.2268H36.0628C35.953 10.4151 35.7333 10.5249 35.4665 10.5249C34.9644 10.5249 34.6035 10.1013 34.6035 9.52069C34.6035 8.84596 35.0429 8.43799 35.5293 8.43799C35.8431 8.43799 36.0314 8.5949 36.1256 8.75182H36.1413L36.157 8.48506H36.612C36.5963 8.62629 36.5963 8.79889 36.5963 9.06564V10.2268H36.5806ZM36.0628 9.30102C36.0628 9.25394 36.0628 9.20687 36.0471 9.15979C36 8.9715 35.8431 8.83027 35.6235 8.83027C35.341 8.83027 35.1213 9.08134 35.1213 9.505C35.1213 9.85021 35.2939 10.1327 35.6235 10.1327C35.8118 10.1327 35.9844 10.0071 36.0471 9.81883C36.0628 9.77176 36.0628 9.6933 36.0628 9.63053V9.30102Z'
        fill='#5E6266'
      />
    </g>
    <defs>
      <clipPath id='clip0_767_1964'>
        <rect width='36.6667' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
