import React from 'react'
import { EloIconProps } from '../types'

export const EloApplePayIcon: React.FC<EloIconProps> = ({ width = 30, height = 12 }) => (
  <svg width={width} height={height} viewBox='0 0 30 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_767_1953)'>
      <path
        d='M5.33981 1.54829C4.99786 1.95356 4.45011 2.27335 3.90235 2.22902C3.83269 1.68127 4.10182 1.09868 4.41528 0.737731C4.75723 0.319789 5.35565 0.0221636 5.84008 0C5.89707 0.569921 5.67543 1.13034 5.33981 1.54829ZM5.83691 2.33351C5.04219 2.28918 4.36145 2.78628 3.98467 2.78628C3.60156 2.78628 3.0253 2.35884 2.39839 2.36834C1.5815 2.381 0.821609 2.84327 0.406833 3.581C-0.448048 5.0533 0.185197 7.23483 1.01158 8.43483C1.41686 9.03008 1.90129 9.67916 2.54087 9.65699C3.14562 9.63483 3.38625 9.26438 4.11765 9.26438C4.85538 9.26438 5.06435 9.65699 5.70393 9.64749C6.36567 9.63483 6.78361 9.05224 7.18889 8.46016C7.65116 7.78575 7.84113 7.13034 7.85063 7.09551C7.83797 7.08285 6.57148 6.59842 6.56198 5.13879C6.54931 3.91662 7.56251 3.33404 7.60683 3.29921C7.03375 2.45066 6.14404 2.35884 5.83691 2.33351ZM10.4184 0.680739V9.581H11.7989V6.53826H13.7113C15.4591 6.53826 16.6844 5.33826 16.6844 3.60317C16.6844 1.86807 15.4812 0.680739 13.7556 0.680739H10.4184ZM11.8021 1.84274H13.3947C14.5947 1.84274 15.2786 2.48232 15.2786 3.60633C15.2786 4.73034 14.5947 5.37625 13.3884 5.37625H11.8021V1.84274ZM19.2111 9.64749C20.0786 9.64749 20.8828 9.20739 21.2501 8.51082H21.2786V9.57784H22.5578V5.14828C22.5578 3.8628 21.5287 3.03641 19.9488 3.03641C18.4828 3.03641 17.3968 3.87546 17.3556 5.02797H18.6C18.7013 4.48021 19.2111 4.11926 19.9076 4.11926C20.753 4.11926 21.2279 4.51187 21.2279 5.23694V5.73087L19.5023 5.83219C17.8971 5.93034 17.0295 6.58575 17.0295 7.72876C17.0295 8.88443 17.9256 9.64749 19.2111 9.64749ZM19.5815 8.59314C18.8438 8.59314 18.3783 8.23852 18.3783 7.6971C18.3783 7.13668 18.8279 6.81372 19.6923 6.75989L21.2279 6.66174V7.16517C21.2279 7.99789 20.5187 8.59314 19.5815 8.59314ZM24.2644 12C25.6132 12 26.2464 11.4871 26.8005 9.92612L29.2258 3.1219H27.82L26.1926 8.381H26.1641L24.5366 3.1219H23.0928L25.4327 9.60317L25.306 9.99578C25.0939 10.6639 24.752 10.9203 24.1409 10.9203C24.0332 10.9203 23.8211 10.9077 23.7356 10.8982V11.9652C23.8179 11.9873 24.1599 12 24.2644 12Z'
        fill='black'
      />
    </g>
    <defs>
      <clipPath id='clip0_767_1953'>
        <rect width='29.3333' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
