import React from 'react'
import { EloIconProps } from '../types'

export const EloSofortIcon: React.FC<EloIconProps> = ({ width = 32, height = 12 }) => (
  <svg width={width} height={height} viewBox='0 0 32 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M30.0031 12H0L1.99691 0H32L30.0031 12Z' fill='#393A41' />
    <path
      d='M9.87131 3.99805C8.52347 3.99805 7.50024 4.99275 7.50024 6.17794C7.50024 7.25025 8.34 7.99805 9.48319 7.99805C10.831 7.99805 11.8472 6.97511 11.8472 5.78994C11.8543 4.71057 11.0145 3.99805 9.87131 3.99805ZM9.56787 6.98923C9.05274 6.98923 8.69284 6.65061 8.69284 6.12855C8.69284 5.55007 9.13742 4.99981 9.77958 4.99981C10.2947 4.99981 10.6546 5.35254 10.6546 5.87459C10.6617 6.46013 10.21 6.98923 9.56787 6.98923Z'
      fill='#EDEDED'
    />
    <path
      d='M17.9796 3.99805C16.6317 3.99805 15.6156 4.99275 15.6156 6.17794C15.6156 7.25025 16.4553 7.99805 17.5985 7.99805C18.9463 7.99805 19.9625 6.97511 19.9625 5.78994C19.9696 4.71057 19.1299 3.99805 17.9796 3.99805ZM17.6832 6.98923C17.168 6.98923 16.8081 6.65061 16.8081 6.12855C16.8081 5.55007 17.2527 4.99981 17.8949 4.99981C18.41 4.99981 18.7699 5.35254 18.7699 5.87459C18.7699 6.46013 18.3253 6.98923 17.6832 6.98923Z'
      fill='#EDEDED'
    />
    <path
      d='M23.9282 5.26309C23.9282 4.54352 23.3708 4.08496 22.3546 4.08496H20.9292L20.301 7.91565H21.5008L21.6982 6.70931H21.7548L22.3898 7.91565H23.766L22.8838 6.57527C23.5401 6.34951 23.9282 5.86275 23.9282 5.26309ZM22.0088 5.89801H21.8324L21.9665 5.09379H22.1288C22.5028 5.09379 22.7004 5.20665 22.7004 5.45357C22.7004 5.74281 22.4181 5.89801 22.0088 5.89801Z'
      fill='#EDEDED'
    />
    <path
      d='M6.12359 5.61357C5.6649 5.38077 5.56611 5.32433 5.56611 5.19734C5.56611 5.04214 5.74959 4.96454 5.99657 4.96454C6.2859 4.96454 6.72342 4.99981 7.15387 5.40898C7.25973 5.07741 7.43615 4.78111 7.66197 4.52009C7.05509 4.17442 6.49054 3.99805 5.9754 3.99805C4.95218 3.99805 4.35941 4.54831 4.35941 5.21145C4.35941 5.78287 4.78987 6.07918 5.29795 6.34019C5.75665 6.57301 5.86955 6.65061 5.86955 6.7917C5.86955 6.9469 5.67902 7.03155 5.42498 7.03155C4.98746 7.03155 4.47936 6.75642 4.17592 6.47423L3.99951 7.53949C4.31001 7.76525 4.76165 7.99805 5.44615 7.99805C6.50466 7.99805 7.07626 7.46189 7.07626 6.77759C7.07626 6.21322 6.7093 5.90986 6.12359 5.61357Z'
      fill='#EDEDED'
    />
    <path
      d='M15.0158 5.76397H13.6044L13.7174 5.09379H15.2275C15.4251 4.69167 15.7284 4.34599 16.1024 4.08496H13.6326C13.0822 4.08496 12.6165 4.45887 12.5388 4.9668L12.059 7.92271H13.2586L13.4562 6.71636H14.8816L14.9875 6.06027C14.9946 5.95445 15.0016 5.86275 15.0158 5.76397Z'
      fill='#EDEDED'
    />
    <path
      d='M23.9565 4.08496C24.2811 4.33188 24.4787 4.67756 24.5211 5.09379H25.5231L25.0644 7.91565H26.264L26.7227 5.09379H27.8377L28 4.08496H23.9565Z'
      fill='#EDEDED'
    />
  </svg>
)
