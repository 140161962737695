import React from 'react'
import { EloIconProps } from '../types'

export const EloPaypalRESTIcon: React.FC<EloIconProps> = ({ width = 63, height = 12 }) => (
  <svg width={width} height={height} viewBox='0 0 63 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.1301 5.79425C16.9975 6.69239 16.3136 6.69239 15.6502 6.69239H15.2726L15.538 5.01858C15.5584 4.91651 15.64 4.84507 15.7421 4.84507H15.9156C16.3647 4.84507 16.7933 4.84507 17.0077 5.10023C17.1403 5.25332 17.1812 5.47785 17.1301 5.79425ZM16.8444 3.45703H14.3541C14.1805 3.45703 14.0377 3.57951 14.007 3.75301L12.9966 10.1421C12.9762 10.2645 13.0783 10.3768 13.2008 10.3768H14.3949C14.5684 10.3768 14.7113 10.2543 14.7419 10.0808L15.0175 8.356C15.0481 8.18249 15.191 8.06002 15.3543 8.06002H16.1401C17.7833 8.06002 18.7325 7.26393 18.9775 5.69218C19.0897 5.00837 18.9775 4.46744 18.6611 4.07961C18.3038 3.68157 17.6711 3.45703 16.8444 3.45703Z'
      fill='#253B80'
    />
    <path
      d='M22.6312 8.08044C22.519 8.76425 21.978 9.22353 21.284 9.22353C20.937 9.22353 20.6614 9.11126 20.4777 8.89693C20.3042 8.69281 20.2328 8.38662 20.294 8.06002C20.4063 7.38642 20.9472 6.90673 21.631 6.90673C21.9678 6.90673 22.2434 7.01899 22.4271 7.23332C22.6108 7.45786 22.6823 7.75384 22.6312 8.08044ZM24.2948 5.76364H23.1007C22.9986 5.76364 22.9068 5.83508 22.8966 5.93714L22.8456 6.27394L22.7639 6.15147C22.5087 5.77384 21.927 5.65137 21.3555 5.65137C20.0389 5.65137 18.906 6.65157 18.6916 8.04982C18.5794 8.74384 18.7427 9.41744 19.1407 9.88693C19.5081 10.3156 20.0286 10.4891 20.641 10.4891C21.7025 10.4891 22.2944 9.80528 22.2944 9.80528L22.2434 10.1319C22.223 10.2544 22.325 10.3768 22.4475 10.3768H23.5192C23.6927 10.3768 23.8356 10.2544 23.8662 10.0808L24.5092 5.99838C24.5194 5.8759 24.4173 5.76364 24.2948 5.76364Z'
      fill='#253B80'
    />
    <path
      d='M30.6431 5.76416H29.4489C29.3367 5.76416 29.2244 5.8254 29.1632 5.91725L27.5098 8.34632L26.8055 6.00911C26.7647 5.86622 26.6218 5.76416 26.4687 5.76416H25.295C25.1521 5.76416 25.0501 5.90705 25.1011 6.03973L26.4177 9.91807L25.1725 11.6735C25.0705 11.8164 25.1725 12.0001 25.346 12.0001H26.5402C26.6524 12.0001 26.7647 11.9491 26.8259 11.847L30.8166 6.09076C30.9084 5.94787 30.8166 5.76416 30.6431 5.76416Z'
      fill='#253B80'
    />
    <path
      d='M34.8991 5.79425C34.7664 6.69239 34.0826 6.69239 33.4192 6.69239H33.0416L33.3069 5.01858C33.3274 4.91651 33.409 4.84507 33.5111 4.84507H33.6846C34.1336 4.84507 34.5623 4.84507 34.7766 5.10023C34.9093 5.25332 34.9501 5.47785 34.8991 5.79425ZM34.6133 3.45703H32.123C31.9495 3.45703 31.8066 3.57951 31.7862 3.75301L30.7758 10.1421C30.7554 10.2645 30.8575 10.3768 30.9799 10.3768H32.2557C32.3782 10.3768 32.4802 10.2952 32.4904 10.1727L32.7762 8.3662C32.8068 8.1927 32.9497 8.07022 33.113 8.07022H33.8989C35.5421 8.07022 36.4913 7.27414 36.7362 5.70239C36.8485 5.01858 36.7362 4.47765 36.4198 4.08981C36.083 3.68157 35.4502 3.45703 34.6133 3.45703Z'
      fill='#179BD7'
    />
    <path
      d='M40.4001 8.08044C40.2879 8.76425 39.7469 9.22353 39.0529 9.22353C38.7059 9.22353 38.4304 9.11126 38.2466 8.89693C38.0731 8.69281 38.0017 8.38662 38.0629 8.06002C38.1752 7.38642 38.7263 6.91693 39.3999 6.91693C39.7367 6.91693 40.0123 7.0292 40.196 7.24353C40.3797 7.45786 40.4512 7.75384 40.4001 8.08044ZM42.0637 5.76364H40.8696C40.7676 5.76364 40.6757 5.83508 40.6655 5.93714L40.6145 6.27394L40.5328 6.15147C40.2777 5.77384 39.6959 5.65137 39.1244 5.65137C37.8078 5.65137 36.6749 6.65157 36.4606 8.04982C36.3483 8.74384 36.5116 9.41744 36.9096 9.88693C37.2771 10.3156 37.7976 10.4891 38.4099 10.4891C39.4714 10.4891 40.0633 9.80528 40.0633 9.80528L40.0123 10.1319C39.9919 10.2544 40.094 10.3768 40.2164 10.3768H41.2881C41.4616 10.3768 41.6045 10.2544 41.6249 10.0808L42.2679 5.99838C42.2883 5.8759 42.1964 5.76364 42.0637 5.76364Z'
      fill='#179BD7'
    />
    <path
      d='M43.4722 3.63049L42.4516 10.142C42.4312 10.2645 42.5332 10.3768 42.6557 10.3768H43.6865C43.86 10.3768 44.0029 10.2543 44.0335 10.0808L45.044 3.69173C45.0644 3.56925 44.9623 3.44678 44.8398 3.44678H43.6865C43.5743 3.45698 43.4824 3.52843 43.4722 3.63049Z'
      fill='#179BD7'
    />
    <path
      d='M2.64753 11.6224L2.84145 10.4079L2.41279 10.3977H0.391968L1.80042 1.4673C1.80042 1.43668 1.82083 1.41627 1.84124 1.39585C1.86166 1.37544 1.89228 1.36523 1.91269 1.36523H5.33176C6.46464 1.37544 7.25052 1.61018 7.65876 2.06946C7.85268 2.28379 7.97515 2.51853 8.03639 2.76348C8.09763 3.02884 8.09763 3.34523 8.03639 3.72286V3.75348V3.99843L8.23031 4.1107C8.39361 4.19234 8.51608 4.29441 8.61814 4.40667C8.78144 4.59039 8.8835 4.83533 8.93454 5.1109C8.98557 5.39667 8.96515 5.74368 8.89371 6.13152C8.80186 6.58059 8.66917 6.96842 8.47526 7.29502C8.30175 7.591 8.07722 7.83595 7.81186 8.01966C7.5567 8.20337 7.26072 8.33605 6.91371 8.42791C6.58712 8.50956 6.20949 8.56059 5.79103 8.56059H5.51547C5.32155 8.56059 5.13784 8.63203 4.99495 8.7545C4.85207 8.87698 4.75 9.05048 4.71939 9.23419L4.69897 9.34646L4.36217 11.4796L4.35196 11.5612C4.35196 11.5816 4.34176 11.602 4.33155 11.602C4.32134 11.6122 4.31114 11.6122 4.30093 11.6122H2.64753V11.6224Z'
      fill='#253B80'
    />
    <path
      d='M8.40376 3.79395C8.39355 3.85518 8.38335 3.92663 8.37314 3.99807C7.91386 6.30466 6.37273 7.10075 4.40294 7.10075H3.40274C3.15779 7.10075 2.96387 7.27425 2.92304 7.50899L2.41274 10.7648L2.26985 11.6833C2.24944 11.8364 2.36171 11.9793 2.525 11.9793H4.29067C4.505 11.9793 4.67851 11.8262 4.70913 11.6221L4.72954 11.5302L5.06634 9.40734L5.08675 9.29507C5.11737 9.09095 5.30108 8.93786 5.50521 8.93786H5.77057C7.49541 8.93786 8.84263 8.23363 9.24067 6.21281C9.40396 5.3657 9.32232 4.66147 8.88345 4.16137C8.76098 4.01848 8.59768 3.89601 8.40376 3.79395Z'
      fill='#179BD7'
    />
    <path
      d='M7.93433 3.59987C7.86288 3.57946 7.79144 3.55905 7.72 3.54884C7.64855 3.52843 7.5669 3.51822 7.49546 3.50801C7.2301 3.46719 6.92392 3.44678 6.60752 3.44678H3.93351C3.87227 3.44678 3.80083 3.45698 3.7498 3.4876C3.62732 3.54884 3.53547 3.66111 3.51505 3.80399L2.94351 7.41698L2.9231 7.51904C2.96392 7.2843 3.16804 7.11079 3.40279 7.11079H4.40299C6.37278 7.11079 7.91392 6.31471 8.36299 3.99791C8.37319 3.92647 8.3834 3.86523 8.39361 3.79379C8.28134 3.73255 8.15886 3.68152 8.02618 3.64069C7.99556 3.62028 7.96495 3.61008 7.93433 3.59987Z'
      fill='#222D65'
    />
    <path
      d='M3.50487 3.80426C3.52529 3.66137 3.61714 3.5491 3.73962 3.48786C3.80085 3.45725 3.86209 3.44704 3.92333 3.44704H6.59735C6.91374 3.44704 7.20972 3.46745 7.48528 3.50828C7.56693 3.51848 7.63838 3.5389 7.70982 3.5491C7.78126 3.56951 7.85271 3.57972 7.92415 3.60013C7.95477 3.61034 7.99559 3.62055 8.02621 3.63075C8.15889 3.67158 8.28136 3.72261 8.39363 3.78384C8.52631 2.92653 8.39363 2.34477 7.93435 1.82426C7.42405 1.24251 6.50549 0.997559 5.33178 0.997559H1.91271C1.66776 0.997559 1.46364 1.17106 1.43302 1.40581L0.00415638 10.4383C-0.0264621 10.6118 0.116424 10.7751 0.289929 10.7751H2.40261L2.93333 7.41724L3.50487 3.80426Z'
      fill='#253B80'
    />
    <path
      d='M46.7065 4.30435V0.0606246H48.6465C49.1841 0.0606246 49.5963 0.204103 49.8833 0.49106C50.1258 0.733559 50.247 1.06093 50.247 1.47318C50.247 2.12388 49.9439 2.5624 49.3377 2.78874L50.3743 4.30435H49.2831L48.3616 2.94636H47.6402V4.30435H46.7065ZM47.6402 2.12186H48.5859C48.8122 2.12186 48.9881 2.0673 49.1133 1.95818C49.2386 1.84501 49.3013 1.69547 49.3013 1.50955C49.3013 1.31151 49.2366 1.16197 49.1073 1.06093C48.9779 0.955849 48.7981 0.903308 48.5677 0.903308H47.6402V2.12186Z'
      fill='#179BD7'
    />
    <path
      d='M51.0876 4.30435V0.0606246H54.2886V0.891183H52.0152V1.75205H54.0158V2.58261H52.0152V3.47379H54.3189V4.30435H51.0876Z'
      fill='#179BD7'
    />
    <path
      d='M56.6586 4.36498C55.9634 4.36498 55.3632 4.13865 54.858 3.68598L55.4097 3.02517C55.826 3.36871 56.2483 3.54048 56.6767 3.54048C56.8667 3.54048 57.0162 3.50411 57.1254 3.43136C57.2345 3.35861 57.2891 3.25757 57.2891 3.12823C57.2891 3.00698 57.2325 2.90998 57.1193 2.83724C57.0061 2.76044 56.7778 2.67759 56.4342 2.58867C56.1917 2.52805 55.9957 2.46945 55.8462 2.41286C55.6966 2.35224 55.5532 2.27343 55.4158 2.17643C55.2824 2.07943 55.1834 1.95818 55.1187 1.81268C55.0581 1.66314 55.0278 1.4853 55.0278 1.27918C55.0278 0.895225 55.1652 0.586039 55.44 0.351623C55.7189 0.117208 56.0766 0 56.5131 0C57.1153 0 57.6407 0.179853 58.0893 0.53956L57.6043 1.24281C57.2042 0.963933 56.8364 0.824496 56.5009 0.824496C56.3271 0.824496 56.1917 0.860871 56.0947 0.93362C56.0018 1.00233 55.9553 1.09327 55.9553 1.20643C55.9553 1.34385 56.0139 1.44691 56.1311 1.51562C56.2483 1.58433 56.4949 1.66718 56.8707 1.76418C57.3315 1.88543 57.671 2.04103 57.8892 2.23099C58.1075 2.42095 58.2166 2.69376 58.2166 3.04942C58.2166 3.46167 58.0731 3.785 57.7862 4.01942C57.5033 4.24979 57.1274 4.36498 56.6586 4.36498Z'
      fill='#179BD7'
    />
    <path d='M59.9262 4.30435V0.921495H58.6349V0.0606246H62.1512V0.921495H60.8598V4.30435H59.9262Z' fill='#179BD7' />
  </svg>
)
