import React from 'react'
import { EloIconProps } from '../types'

export const EloVisaIcon: React.FC<EloIconProps> = ({ width = 39, height = 12 }) => (
  <svg width={width} height={height} viewBox='0 0 39 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M15.8431 0.279785L13.8445 12H17.0394L19.0365 0.279785H15.8431Z' fill='#00579F' />
    <path
      d='M28.2116 0.494109C27.5916 0.258961 26.6088 0 25.3907 0C22.2865 0 20.1011 1.58204 20.0871 3.84274C20.0622 5.5111 21.6525 6.4383 22.8441 6.99492C24.059 7.56344 24.4733 7.93402 24.4733 8.44152C24.4609 9.21841 23.4905 9.57857 22.5855 9.57857C21.3301 9.57857 20.6572 9.39253 19.6354 8.95944L19.2211 8.77341L18.7819 11.3809C19.5186 11.7023 20.8768 11.9881 22.2865 12C25.5854 12 27.7334 10.4433 27.7583 8.03225C27.7708 6.71065 26.9312 5.69713 25.1197 4.86965C24.0216 4.33834 23.3472 3.97966 23.3472 3.43644C23.3596 2.94084 23.9157 2.43483 25.1571 2.43483C26.1789 2.40952 26.9312 2.64467 27.4997 2.87982L27.7848 3.00335L28.2116 0.494109Z'
      fill='#00579F'
    />
    <path
      d='M36.2695 0.210449H33.8365C33.0873 0.210449 32.5188 0.420297 32.1948 1.17337L27.5251 11.8265H30.8241C30.8241 11.8265 31.3677 10.3933 31.483 10.0837H35.5203C35.6091 10.4915 35.8941 11.8265 35.8941 11.8265H38.8053L36.2695 0.210449ZM32.3895 7.71138C32.6481 7.04463 33.6434 4.46098 33.6434 4.46098C33.6309 4.48628 33.902 3.78083 34.0577 3.34923L34.2773 4.34936C34.2773 4.34936 34.8723 7.13095 35.0016 7.71138H32.3895Z'
      fill='#00579F'
    />
    <path
      d='M11.0457 0.210449L7.96791 8.13107L7.63147 6.52522C7.06139 4.67082 5.27638 2.65718 3.28577 1.65557L6.10502 11.8146H9.42894L14.3696 0.210449H11.0457Z'
      fill='#00579F'
    />
    <path
      d='M5.10892 0.210449H0.0514007L0 0.445597C3.94384 1.40851 6.55749 3.73321 7.63067 6.52522L6.53257 1.18676C6.35033 0.444109 5.79427 0.234262 5.10892 0.210449Z'
      fill='#FAA61A'
    />
  </svg>
)
