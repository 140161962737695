import React from 'react'
import { EloIconProps } from '../types'

export const EloIdealIcon: React.FC<EloIconProps> = ({ width = 46, height = 40 }) => (
  <svg width={width} height={height} viewBox='0 0 46 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M32.4761 3.24136L25.5251 2.65909L2.1723 2.30826V38.1929H27.1L35.4067 35.937L41.0611 31.0419L43.8269 23.3424V14.4468L39.6907 7.29958L32.4761 3.24136Z'
      fill='white'
    />
    <path
      d='M2.91002 2.91045V37.0887H23.7035C36.2598 37.0887 43.0903 31.2477 43.0903 19.9559C43.0903 8.35178 35.6864 2.91045 23.7035 2.91045H2.91002ZM23.7035 1.13664C42.1172 1.13664 44.8667 12.9285 44.8667 19.9559C44.8667 32.1488 37.3513 38.8634 23.7035 38.8634C23.7035 38.8634 1.57889 38.8634 1.13336 38.8634C1.13336 38.4167 1.13336 1.58223 1.13336 1.13664C1.57889 1.13664 23.7035 1.13664 23.7035 1.13664Z'
      fill='#0A0B09'
    />
    <path
      d='M15.7386 14.6569C15.7386 15.7256 15.7386 17.4451 15.7386 18.5134C16.325 18.5134 16.9239 18.5134 16.9239 18.5134C17.7452 18.5134 18.5038 18.2764 18.5038 16.5549C18.5038 14.8725 17.6597 14.6569 16.9239 14.6569C16.9239 14.6569 16.325 14.6569 15.7386 14.6569ZM35.5121 12.7387H37.4294C37.4294 12.7387 37.4294 17.1044 37.4294 18.5134C37.8546 18.5134 39.0224 18.5134 40.2708 18.5134C39.4915 8.03512 31.2372 5.77122 23.7369 5.77122H15.7402V12.7427H16.9239C19.082 12.7427 20.4228 14.2041 20.4228 16.5549C20.4228 18.9803 19.1142 20.4281 16.9239 20.4281H15.7402V34.3774H23.7369C35.9316 34.3774 40.1754 28.7221 40.3252 20.4281H35.5121V12.7387ZM30.1697 17.0293H31.5569L30.9226 14.8555H30.8074L30.1697 17.0293ZM27.1775 20.4304L29.4995 12.7384H32.2274L34.5487 20.4304H32.5526L32.1175 18.9424H29.6093L29.173 20.4304H27.1775ZM26.2567 18.5134V20.4281H21.5131V12.7416H26.1035V14.6555C26.1035 14.6555 24.4699 14.6555 23.4308 14.6555C23.4308 14.9194 23.4308 15.222 23.4308 15.5466H25.9586V17.46H23.4308C23.4308 17.8443 23.4308 18.2066 23.4308 18.5134C24.5 18.5134 26.2567 18.5134 26.2567 18.5134Z'
      fill='#D50172'
    />
    <path
      d='M13.2293 16.5816C13.2293 18.8636 11.3777 20.7137 9.09264 20.7137C6.80863 20.7137 4.95526 18.8636 4.95526 16.5816C4.95526 14.3014 6.80863 12.4505 9.09264 12.4505C11.3777 12.4505 13.2293 14.3014 13.2293 16.5816ZM5.77425 34.3712H12.4307V22.5376H5.77425V34.3712Z'
      fill='black'
    />
  </svg>
)
