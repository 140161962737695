import React from 'react'
import { EloIconProps } from '../types'

export const EloPaypalNVPIcon: React.FC<EloIconProps> = ({ width = 60, height = 12 }) => (
  <svg width={width} height={height} viewBox='0 0 60 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.2174 5.76318C17.084 6.6659 16.3967 6.6659 15.7299 6.6659H15.3504L15.6171 4.98356C15.6376 4.88098 15.7197 4.80917 15.8223 4.80917H15.9966C16.448 4.80917 16.8788 4.80917 17.0943 5.06563C17.2276 5.2195 17.2687 5.44518 17.2174 5.76318ZM16.9301 3.41406H14.4271C14.2528 3.41406 14.1091 3.53716 14.0784 3.71155L13.0628 10.1332C13.0423 10.2563 13.1449 10.3691 13.268 10.3691H14.4682C14.6426 10.3691 14.7862 10.246 14.817 10.0716L15.0939 8.33798C15.1247 8.16359 15.2683 8.04049 15.4324 8.04049H16.2223C17.8739 8.04049 18.8279 7.24036 19.0741 5.6606C19.1869 4.9733 19.0741 4.42962 18.7561 4.03981C18.3971 3.63974 17.761 3.41406 16.9301 3.41406Z'
      fill='#253B80'
    />
    <path
      d='M22.7466 8.06107C22.6337 8.74837 22.09 9.20999 21.3925 9.20999C21.0437 9.20999 20.7667 9.09715 20.5821 8.88172C20.4077 8.67656 20.3359 8.36882 20.3974 8.04056C20.5103 7.36352 21.054 6.88138 21.7413 6.88138C22.0798 6.88138 22.3568 6.99422 22.5414 7.20964C22.726 7.43532 22.7979 7.73281 22.7466 8.06107ZM24.4186 5.73247H23.2184C23.1159 5.73247 23.0235 5.80428 23.0133 5.90686L22.962 6.24538L22.8799 6.12228C22.6235 5.74273 22.0387 5.61963 21.4643 5.61963C20.141 5.61963 19.0023 6.62493 18.7869 8.0303C18.6741 8.72785 18.8382 9.40489 19.2383 9.87677C19.6076 10.3076 20.1307 10.482 20.7462 10.482C21.8131 10.482 22.408 9.7947 22.408 9.7947L22.3568 10.123C22.3362 10.2461 22.4388 10.3692 22.5619 10.3692H23.639C23.8134 10.3692 23.957 10.2461 23.9878 10.0717L24.6341 5.96841C24.6443 5.84531 24.5417 5.73247 24.4186 5.73247Z'
      fill='#253B80'
    />
    <path
      d='M30.7992 5.73242H29.599C29.4861 5.73242 29.3733 5.79397 29.3117 5.88629L27.6499 8.32774L26.9421 5.97862C26.9011 5.835 26.7574 5.73242 26.6036 5.73242H25.4239C25.2803 5.73242 25.1777 5.87604 25.229 6.00939L26.5523 9.90749L25.3008 11.6719C25.1982 11.8155 25.3008 12.0002 25.4752 12.0002H26.6754C26.7882 12.0002 26.9011 11.9489 26.9626 11.8463L30.9735 6.06068C31.0659 5.91707 30.9735 5.73242 30.7992 5.73242Z'
      fill='#253B80'
    />
    <path
      d='M35.0769 5.76318C34.9435 6.6659 34.2562 6.6659 33.5894 6.6659H33.2099L33.4766 4.98356C33.4971 4.88098 33.5792 4.80917 33.6817 4.80917H33.8561C34.3075 4.80917 34.7383 4.80917 34.9538 5.06563C35.0871 5.2195 35.1281 5.44518 35.0769 5.76318ZM34.7896 3.41406H32.2866C32.1122 3.41406 31.9686 3.53716 31.9481 3.71155L30.9326 10.1332C30.912 10.2563 31.0146 10.3691 31.1377 10.3691H32.42C32.5431 10.3691 32.6457 10.287 32.6559 10.1639L32.9432 8.34824C32.9739 8.17385 33.1175 8.05075 33.2817 8.05075H34.0716C35.7231 8.05075 36.6771 7.25062 36.9233 5.67086C37.0362 4.98356 36.9233 4.43988 36.6053 4.05007C36.2668 3.63974 35.6308 3.41406 34.7896 3.41406Z'
      fill='#179BD7'
    />
    <path
      d='M40.6059 8.06107C40.4931 8.74837 39.9494 9.20999 39.2519 9.20999C38.9031 9.20999 38.6261 9.09715 38.4415 8.88172C38.2671 8.67656 38.1953 8.36882 38.2568 8.04056C38.3697 7.36352 38.9236 6.89164 39.6006 6.89164C39.9392 6.89164 40.2161 7.00448 40.4008 7.2199C40.5854 7.43532 40.6572 7.73281 40.6059 8.06107ZM42.278 5.73247H41.0778C40.9752 5.73247 40.8829 5.80428 40.8726 5.90686L40.8214 6.24538L40.7393 6.12228C40.4828 5.74273 39.8981 5.61963 39.3237 5.61963C38.0004 5.61963 36.8617 6.62493 36.6463 8.0303C36.5334 8.72785 36.6976 9.40489 37.0976 9.87677C37.4669 10.3076 37.9901 10.482 38.6056 10.482C39.6724 10.482 40.2674 9.7947 40.2674 9.7947L40.2161 10.123C40.1956 10.2461 40.2982 10.3692 40.4213 10.3692H41.4984C41.6728 10.3692 41.8164 10.2461 41.8369 10.0717L42.4832 5.96841C42.5037 5.84531 42.4114 5.73247 42.278 5.73247Z'
      fill='#179BD7'
    />
    <path
      d='M43.6937 3.58846L42.6679 10.1332C42.6474 10.2563 42.75 10.3691 42.8731 10.3691H43.9091C44.0835 10.3691 44.2272 10.246 44.2579 10.0716L45.2735 3.65C45.294 3.52691 45.1914 3.40381 45.0683 3.40381H43.9091C43.7963 3.41407 43.704 3.48587 43.6937 3.58846Z'
      fill='#179BD7'
    />
    <path
      d='M2.6611 11.6205L2.856 10.3998L2.42516 10.3895H0.394043L1.80967 1.41362C1.80967 1.38284 1.83019 1.36233 1.8507 1.34181C1.87122 1.32129 1.90199 1.31104 1.92251 1.31104H5.35899C6.49765 1.32129 7.28753 1.55723 7.69785 2.01885C7.89276 2.23427 8.01586 2.47021 8.07741 2.7164C8.13896 2.98312 8.13896 3.30112 8.07741 3.68067V3.71145V3.95764L8.27231 4.07048C8.43644 4.15255 8.55954 4.25513 8.66212 4.36797C8.82625 4.55261 8.92883 4.79881 8.98013 5.07578C9.03142 5.36301 9.0109 5.71179 8.93909 6.1016C8.84677 6.55296 8.71341 6.94277 8.51851 7.27103C8.34412 7.56851 8.11844 7.81471 7.85173 7.99936C7.59527 8.184 7.29779 8.31736 6.94901 8.40968C6.62075 8.49175 6.2412 8.54304 5.82061 8.54304H5.54364C5.34874 8.54304 5.16409 8.61485 5.02047 8.73795C4.87686 8.86104 4.77428 9.03543 4.7435 9.22008L4.72299 9.33292L4.38447 11.4769L4.37421 11.5589C4.37421 11.5795 4.36395 11.6 4.35369 11.6C4.34344 11.6102 4.33318 11.6102 4.32292 11.6102H2.6611V11.6205Z'
      fill='#253B80'
    />
    <path
      d='M8.44662 3.75244C8.43636 3.81399 8.4261 3.8858 8.41584 3.9576C7.95422 6.27595 6.40524 7.07609 4.42542 7.07609H3.42011C3.17392 7.07609 2.97901 7.25047 2.93798 7.48641L2.42507 10.7588L2.28146 11.682C2.26094 11.8359 2.37378 11.9795 2.53791 11.9795H4.31258C4.528 11.9795 4.70239 11.8256 4.73316 11.6205L4.75368 11.5281L5.0922 9.39443L5.11271 9.28159C5.14349 9.07643 5.32813 8.92256 5.5333 8.92256H5.80001C7.53364 8.92256 8.88772 8.21474 9.28778 6.18363C9.45191 5.3322 9.36985 4.62439 8.92875 4.12174C8.80565 3.97812 8.64152 3.85502 8.44662 3.75244Z'
      fill='#179BD7'
    />
    <path
      d='M7.97475 3.55768C7.90294 3.53716 7.83113 3.51665 7.75933 3.50639C7.68752 3.48587 7.60545 3.47562 7.53365 3.46536C7.26693 3.42432 6.95919 3.40381 6.64119 3.40381H3.95355C3.892 3.40381 3.82019 3.41407 3.7689 3.44484C3.6458 3.50639 3.55348 3.61923 3.53296 3.76284L2.9585 7.39423L2.93799 7.49682C2.97902 7.26088 3.18418 7.08649 3.42012 7.08649H4.42542C6.40525 7.08649 7.95423 6.28635 8.40559 3.95775C8.41585 3.88594 8.42611 3.82439 8.43636 3.75259C8.32352 3.69104 8.20043 3.63975 8.06707 3.59871C8.0363 3.5782 8.00552 3.56794 7.97475 3.55768Z'
      fill='#222D65'
    />
    <path
      d='M3.52273 3.76289C3.54324 3.61928 3.63557 3.50644 3.75867 3.44489C3.82021 3.41411 3.88176 3.40385 3.94331 3.40385H6.63095C6.94895 3.40385 7.24644 3.42437 7.52341 3.4654C7.60548 3.47566 7.67728 3.49618 7.74909 3.50644C7.8209 3.52695 7.8927 3.53721 7.96451 3.55773C7.99529 3.56798 8.03632 3.57824 8.06709 3.5885C8.20045 3.62953 8.32355 3.68082 8.43639 3.74237C8.56974 2.88069 8.43639 2.29597 7.97477 1.77281C7.46186 1.18809 6.53863 0.941895 5.35894 0.941895H1.92245C1.67626 0.941895 1.47109 1.11628 1.44032 1.35222L0.00417755 10.4307C-0.0265969 10.6051 0.117017 10.7692 0.291406 10.7692H2.41485L2.94827 7.39428L3.52273 3.76289Z'
      fill='#253B80'
    />
    <path
      d='M46.9445 4.26534V0H47.8097L49.8083 2.62623V0H50.7345V4.26534H49.9363L47.8706 1.5538V4.26534H46.9445Z'
      fill='#179BD7'
    />
    <path
      d='M53.1173 4.29581L51.3928 0H52.4287L53.5438 3.00402L54.6589 0H55.6704L53.946 4.29581H53.1173Z'
      fill='#179BD7'
    />
    <path
      d='M56.3285 4.26534V0H58.0712C58.5749 0 58.973 0.136085 59.2654 0.408255C59.5579 0.680424 59.7042 1.03993 59.7042 1.48678C59.7042 1.96206 59.5417 2.33172 59.2167 2.59577C58.8917 2.85575 58.4794 2.98574 57.9798 2.98574H57.2668V4.26534H56.3285ZM57.2668 2.15095H58.0102C58.2418 2.15095 58.4225 2.09002 58.5525 1.96815C58.6866 1.84629 58.7536 1.68786 58.7536 1.49287C58.7536 1.2857 58.6866 1.12727 58.5525 1.01759C58.4185 0.903847 58.2316 0.846976 57.9919 0.846976H57.2668V2.15095Z'
      fill='#179BD7'
    />
  </svg>
)
